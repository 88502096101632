namespace eh {
  

  export class EventOverview {
    static init($base: JQuery<HTMLElement>): void {
      $('.eh-event-overview-table', $base).each((index, element) => {
        new EventOverview($(element));
      });    
    }

    constructor(private readonly base:JQuery<HTMLElement>) {
      let bt = base.find('.js-show-more');
      let sz = bt.data('initialSize') || 0;
      bt.on('click', (ev) => {
        base.find('.eh-event-overview-table--item').removeClass('eh--hide');
        bt.addClass('eh--hide');
      });
      let hidden = false;
      base.find('.eh-event-overview-table--item').each((idx, el) => {
        if(!sz || idx < sz) {
          el.classList.remove('eh--hide');
        } else {
          el.classList.add('eh--hide');
          hidden = true;
        }
      });
      if(!hidden) {
        bt.addClass('eh--hide');
      }
    }
  }
}
