namespace eh {
  
  export class SearchFilters {
    
    private static readonly CLASS_ACTIVE = 'active';
    private static readonly CLASS_DISABLED = 'disabled';
    private static readonly CLASS_FILTER_ACTIVE = 'eh-filter-active';
    private static readonly CLASS_HIDDEN = 'eh--hide';
    private static readonly CLASS_LOADING = 'is-loading';
    private static readonly SELECTOR__PRODUCT_FILTER_GROUP = '.eh-product-filter--group';
    /**
     * The widget root element
     */
    private static readonly SELECTOR__PRODUCT_FINDER = '.eh-product-finder';
    /**
     * The container of the filter (form) controls
     */
    private static readonly SELECTOR__SEARCH_FILTERS = '.eh-search-filter:not(.is-legacy)';
    public static readonly SELECTOR__FILTER_RESULT = '.marker-filter-result';
    private static readonly SELECTOR__MARKER_FILTER_SELECT = 'select.marker-filter-select';
  
    public static clippingManager: ClippingPainterManager;

    private static searchFilterFilterButtonBehavior: SearchFilterFilterButtonBehavior;
    // workaround: group select2 fields with accordion behavior by data attribute (data-s2group="GROUP")
    // DLA cards 'language select' / 'language chooser inline' should not close product filters
    private static ignoreS2After: string  = '';

    static init($base: JQuery<HTMLElement>, isSnippetRequest:boolean): void {
      const $container = $base.selfOrFind(SearchFilters.SELECTOR__SEARCH_FILTERS);
      if ($container.length === 0) {
        return;
      }
      $container.each((idx, container) =>{
        SearchFilters.initFilterSelects($(container), !isSnippetRequest);
      });
  
      /*
      refresh tab label with result count
       */
      if (isSnippetRequest) {
        SearchFilters.refreshTabLabel($base);
      }

      // filter button behavior
      if (!SearchFilters.searchFilterFilterButtonBehavior) {
        SearchFilters.searchFilterFilterButtonBehavior = new SearchFilterFilterButtonBehavior($base);
      }
      
      if (isSnippetRequest) {
        SearchFilters.searchFilterFilterButtonBehavior.update();
      }

      if (!isSnippetRequest) {
        $(':root').on(TAB_EVENTS.TAB_CHANGE, SearchFilters.scrollToContentTop);
      }
      
    }

    private static scrollToContentTop(_e: JQuery.TriggeredEvent, t: ITab | HTMLElement | null): void {
      if (t instanceof Tab && !t.isOwnerById('tabs-result-categories')) {
        // scoped to main tabs only
        return;
      }
      const targetThresholdSelector: string = '.eh-tabs--products-sticky-wrapper';
      const targetThresholdElement: Element | null = document.querySelector(targetThresholdSelector);
      if (targetThresholdElement) {
        const targetThreshold: number = offsetTopToBody(targetThresholdElement as HTMLElement);
        const currentScrollPos: number = ScrollPage.getScrollPosition();
        if (currentScrollPos > targetThreshold) {
          ScrollPage.scrollToAnimated(targetThreshold);
        }
      }
    }
    
    private static initFilterSelects($filterContainer: JQuery<HTMLElement>, initial:boolean) {
      Breakpoints.getInstance().registerChangeListener((old, current) => {
        if (current.isMobile && !old?.isMobile) {
          $('.eh-product-filter').removeClass('eh-filter-active');
        }
      });
      const $productFinder = $filterContainer.selfOrClosest(SearchFilters.SELECTOR__PRODUCT_FINDER);
      if (initial) {
        new BadgeBehaviorManager($productFinder, $filterContainer);
        SearchFilters.clippingManager = new ClippingPainterManager($filterContainer);
      }

      $('.marker-select-eh', $filterContainer).each((_index, elem) => {
        const $select = $(elem);
        const opts: Select2.Options = {
          allowClear: !$select.data('disallowResultClear'),
          theme: 'endress',
          width: '100%',
          minimumResultsForSearch: $select.data('minResultsForSearch') || 6,
          closeOnSelect: false,
          placeholder: $select.data('placeholder') || 'PLACEHOLDER'
        };
        $select.select2(opts);
      });
      
      $(`select:not(${SearchFilters.SELECTOR__MARKER_FILTER_SELECT})`).on('select2:open', ($event) => { 
        this.ignoreS2After = ''+($($event.currentTarget).data('s2group') || '');
        if(this.ignoreS2After) {
          setTimeout(function() { 
            eh.SearchFilters.ignoreS2After = ''; 
          });
        }
      });

      $(SearchFilters.SELECTOR__MARKER_FILTER_SELECT, $filterContainer).each((_index, elem) => {
        this.initSelectTwo($(elem));
      })
      .on('select2:open', ($event) => { 
        $('.select2-search__field', $($event.currentTarget).data('select2').$container)
            .prop('focus', false);
      })
      .on('select2:closing', ($event) => {
        if(this.ignoreS2After !== '') { 
          $event.preventDefault();
          return; 
        }
        const $select2 = $($event.currentTarget);
        // select2 doesn't unselect items if not in multiple mode => prevent closing, but clear selection
        if ($select2.selfOrClosest(SearchFilters.SELECTOR__PRODUCT_FILTER_GROUP).siblings(SearchFilters.SELECTOR__PRODUCT_FILTER_GROUP).length === 0 && !$select2.hasClass('marker-product-segment')) {
          $event.preventDefault();
          //@ts-ignore
          $select2.val(null).trigger('change');
        }
      }).on('select2:selecting', ($event) => {
          //@ts-ignore
          const optEl = $event.params.args.data.element || undefined;
          if(optEl !== undefined && $(optEl).hasClass('eh-disabled')) {
            $event.preventDefault();
          }
      });
  
      $('.trigger-filter-remove', $filterContainer.selfOrClosest('.marker-tab-content')).on('click', ($event) => {
        $event.preventDefault();
        const $el = $($event.currentTarget);
        const paramName = $el.data('filterRemoveParamName');
        const paramValue = $el.data('filterRemoveParamValue');
        $el.selfOrClosest('.eh-badge').addClass(SearchFilters.CLASS_LOADING);
        const $select = $(`select[data-component-parameter="${paramName}"]`, $filterContainer);
        const $option = $(`option[value="${paramValue}"]`, $select);
        $option.prop('selected', false);
        $(`input[data-component-parameter="${paramName}"]`, $filterContainer).each((_idx, el) => {
          const $input = $(el);
          if (['checkbox', 'radio'].indexOf($input.prop('type')) !== -1) {
            $input.prop('checked', false);
          }
          else {
            $input.val('');
          }
        });
        $productFinder.removeData('lastSelectedFilter');
        $(':root').on(cs.Snippet.EventIdPreReplace, removeFilterActive);
        $form.trigger('submit');
      });

      $('.trigger-filter-remove-all', $filterContainer.selfOrClosest('.marker-tab-content')).on('click', ($event) => {
        $event.preventDefault();
        const $el = $($event.currentTarget);
        $el.selfOrClosest('.eh-badges--reset-button')
            .siblings('.eh-badges')
            .find('.eh-badge')
            .addClass(SearchFilters.CLASS_LOADING);
        const $select = $('select', $filterContainer);
        $('option', $select).prop('selected', false);
        $('input', $filterContainer).each((_idx, el) => {
          const $input = $(el);
          const type = $input.prop('type');
          if (['checkbox', 'radio'].indexOf(type) !== -1) {
            $input.prop('checked', false);
          }
          else if (type !== 'hidden') {
            $input.val('');
          }
        });
        $productFinder.removeData('lastSelectedFilter');
        $(':root').on(cs.Snippet.EventIdPreReplace, removeFilterActive);
        $form.trigger('submit');
      });


      $('.trigger-dla-remove-all', $filterContainer.selfOrClosest('.marker-tab-content')).on('click', ($event) => {
        $event.preventDefault();
        const $el = $($event.currentTarget);
        if($el.hasClass('disabled')) {
          return;
        }
        const $select = $('select.marker-select-dla', $filterContainer);
        $('option', $select).prop('selected', false);
        const $wrapper = $('.marker-filter-radios, .marker-filter-dla-accordion', $filterContainer);
        const $input = $('input[type=radio]', $wrapper);
        const $inp = $('input[type=hidden]', $wrapper);
        $input.prop('checked', false);
        $inp.val('').trigger('change');
        $select.trigger('change');
        eh.Tracking.injectTrackingEvent($.extend({}, eh.Tracking.analyzeFilterParams([]),
          {'event_subtype': 'filter_click'}), $el, 'filter');
      });
    
      const $form = $filterContainer.selfOrFind('form.csSnippetForm');
      const $fields = $form.find('input, select, textarea');
      const $buttonShow = $('.trigger-filter-show', $filterContainer.selfOrClosest('.marker-tab-content'));
      const $buttonBack = $('.trigger-filter-back', $filterContainer);
      const $buttonCancel = $('.trigger-filter-cancel', $filterContainer);
      const $buttonSubmit = $('.trigger-filter-submit', $filterContainer);
  
      const handleSnippetFormSubmit = ($event: cs.SnippetEventFormSubmit) => {
        if($event.$form.hasClass('marker-dla-form')) {
          $('.trigger-dla-remove-all, .marker-dla-back').toggleClass(SearchFilters.CLASS_DISABLED, true);
        }
        if (!$form.is($event.$form)) {
          return;
        }
        if (eh.Breakpoints.getInstance().isMobile) {
          /*
            If snippet form is submitted in mobile view, scroll results to top
            Show progress indicator inside close aka submit button
          */
          ScrollPage.scrollToAnimated(0);
          $buttonSubmit.toggleClass(SearchFilters.CLASS_LOADING, true)
            .toggleClass(SearchFilters.CLASS_DISABLED, true);
        }
      };
      $(':root').on(cs.Snippet.EventIdFormSubmit, handleSnippetFormSubmit);
      
      const handleFormFieldChange = ($event: JQuery.TriggeredEvent) => {
        const $formField = $($event.currentTarget);
        const formFieldId = $formField.attr('id');
        if (formFieldId) { // track selected filter to restore open state after reload
          let prev = $productFinder.data('lastSelectedFilter') || '';
          $productFinder.data('lastSelectedFilter', formFieldId);
          if(prev !== formFieldId){
            toggleFilterDisplay($($event.currentTarget), true);
            $buttonSubmit.toggleClass('eh-cta-button--01', false)
                .toggleClass('eh-cta-button--02', true);
            $buttonSubmit.selfOrFind('[data-label-part-id="close"]').toggleClass(SearchFilters.CLASS_HIDDEN, true);
            const resultCount: string = $filterContainer.data('resultCount') ?? ($('input[type=radio]:checked',$filterContainer).data('count') ?? '');
            $buttonSubmit.selfOrFind('[data-label-part-id="result"]')
                .toggleClass(SearchFilters.CLASS_HIDDEN, false)
                .each((_index, el) => {
                  SearchFilters.translateLabelWithResult($(el), resultCount);
                });
          }
        }
      };
      $fields.on('change', handleFormFieldChange);

      $buttonShow.on('click', ($event) => {
        $event.preventDefault();
        toggleFilterDisplay($($event.currentTarget), true);
      });
      $buttonCancel.on('click', ($event) => {
        $event.preventDefault();
        $productFinder.removeData('lastSelectedFilter');
        toggleFilterDisplay($($event.currentTarget), false);
      });
      $buttonSubmit.on('click', ($event) => {
        $event.preventDefault();
        $productFinder.removeData('lastSelectedFilter');
        toggleFilterDisplay($($event.currentTarget), false);
      });

      const removeFilterActive = (event: cs.SnippetEventPreReplace) => {
        $(event.newElement).find('.eh-filter-active').removeClass("eh-filter-active")
        $(':root').off(cs.Snippet.EventIdPreReplace, removeFilterActive);
      };

      const toggleFilterDisplay = ($button: JQuery<HTMLElement>, show: boolean) => {
        const $filterControl: JQuery<HTMLElement> = $button.closest('.marker-search-container')
            .find('.eh-product-filter');
        $filterControl.toggleClass(SearchFilters.CLASS_FILTER_ACTIVE, show);
        ScrollPage.setScrollEnabled(!show, 'eh-no-scroll--lt-medium eh-layout--pos-fixed--lt-medium' +
            ' eh-full-height--lt-medium');
        $buttonSubmit
            .toggleClass('eh-cta-button--01', true)
            .toggleClass('eh-cta-button--02', false);
        $buttonSubmit.selfOrFind('[data-label-part-id="result"]').toggleClass(SearchFilters.CLASS_HIDDEN, true);
        $buttonSubmit.selfOrFind('[data-label-part-id="close"]').toggleClass(SearchFilters.CLASS_HIDDEN, false);
        requestAnimationFrame(SearchFilters.clippingManager.init);
        
        const trackingTitle = $button.data('csTrackingTitle');
        if (trackingTitle) {
          const formParams: { 'k': string; 'v': string; }[] = [
            {
              k: trackingTitle,
              v: '' + $button.val()
            }
          ];
          eh.Tracking.injectTrackingEvent($.extend({}, eh.Tracking.analyzeFilterParams(formParams),
              {'event_subtype': 'filter_click'}), $button, 'filter');
        }
      };
      
      if (!initial) {
        if (eh.Breakpoints.getInstance().isMobile) {
          /*
          A snippet request in mobile view opens the filter automatically if a search criteria was added
          Result count is added to the close button label
          */
          if ($productFinder.data('lastSelectedFilter')) {
            $productFinder.removeData('lastSelectedFilter');
            toggleFilterDisplay($buttonShow, true);
            $buttonSubmit.toggleClass('eh-cta-button--01', false)
              .toggleClass('eh-cta-button--02', true);
          }
          $buttonSubmit.selfOrFind('[data-label-part-id="close"]').toggleClass(SearchFilters.CLASS_HIDDEN, true);
          const resultCount: string = $filterContainer.data('resultCount') ?? '';
          $buttonSubmit.selfOrFind('[data-label-part-id="result"]')
              .toggleClass(SearchFilters.CLASS_HIDDEN, false)
            .each((_index, el) => {
              SearchFilters.translateLabelWithResult($(el), resultCount);
          });
        }
      }
    
      $('.eh-product-filter--group-content', $filterContainer).on('click', ($event) => {
        const $filter = $($event.currentTarget).closest('.eh-product-filter--group:not(.disabled.no-autohide)');
        if ($filter.length === 0) {
          return;
        }
      
        // inline boolean checkbox special case - no accordion effect
        const $checkbox = $($event.currentTarget).find('.marker-inline-checkbox:visible');
        if ($checkbox.length > 0) {
          $checkbox.prop('checked', !$checkbox.prop('checked'));
          $checkbox.trigger('change');
          return;
        }
        $event.preventDefault();
      
        if ($filter.hasClass(SearchFilters.CLASS_ACTIVE)) {
          SearchFilters.makeInActive($filter)
        }
        else {
          SearchFilters.makeActive($filter);
        }
      });

      $('.eh-product-filter--accordion-content', $filterContainer).on('click', ($event) => {
        const elem = $($event.currentTarget);
        const input = elem.closest('.eh-product-filter-accordion--group').find('input[type=hidden]');
        const val = elem.data('filterGroupValue') || input.val();

        if(val !== input.val()) {
          input.val(val);
          input.trigger('change');
        }
      });
      
      let $filter:JQuery<HTMLElement> | undefined = undefined;
      if(initial) {// open filter requested by URL
        const doopen: string | null = this.getQueryParamValue('jsopen');
        if(doopen !== null && doopen.length > 0) {
          let sel = '#'+$.escapeSelector(doopen+'[]');
          $filter = $productFinder.find(sel).closest('.eh-product-filter--group:not(.disabled.no-autohide)').emptyToOptional();
          if($filter === undefined) {
            sel = '#'+$.escapeSelector(doopen);
            $filter = $productFinder.find(sel).closest('.eh-product-filter--group:not(.disabled.no-autohide)').emptyToOptional();
          }
        }
      }
      if($filter === undefined) {
        /*
        open last selected or first filter on load
        */
        const lastSelectedFieldId: string | undefined = $productFinder.data('lastSelectedFilter');
        $filter = lastSelectedFieldId ?
          $(`#${$.escapeSelector(lastSelectedFieldId)}`,
              $filterContainer).closest('.eh-product-filter--group:not(.disabled.no-autohide)').emptyToOptional()
          : undefined;
        if(lastSelectedFieldId !== undefined && $filter === undefined) {
          $filter = $(`input:hidden[name="${lastSelectedFieldId}"]`).closest('.eh-product-filter-accordion--group').find('.marker-initial-open').emptyToOptional();
          $filter?.removeClass('marker-initial-open');
          // click??
        }  
        $filter = $filter ?? $('.eh-product-filter--group:not(.disabled.no-autohide):first', $filterContainer).emptyToOptional();
      }
    
      $('.eh-product-filter--group.no-autohide', $filterContainer).each((i, ele) => {
        window.setTimeout(() => {
          $(ele).toggleClass(SearchFilters.CLASS_ACTIVE, true);
          $(ele).find(SearchFilters.SELECTOR__MARKER_FILTER_SELECT).select2('open');
        }, 200);
      });
      if ($filter !== undefined) {
        window.setTimeout(() => {
          SearchFilters.makeActive($filter!);
        }, 200);
      }
    }
    
    private static getQueryParamValue(name: string): string | null {
      let url = location.search;
      name = name.replace(/[\[\]]/g, '\\$&');
      var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
          results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }
    
    private static translateLabelWithResult($el: JQuery<HTMLElement>, resultCount: string) {
      const tpl = $el.data('translationTemplate');
      if (tpl) {
        let count = parseInt(resultCount) > 9999 ? '9999+' : resultCount;
        $el.text((idx,txt) => {
          console.log("tpl", tpl);
          console.log("count: ", count)
          let replaced= tpl.replace('999999', count).replace('$c', count)
          console.log("replaced", replaced);
          return replaced;
        });
      }
    }
    
    private static initSelectTwo($select: JQuery<HTMLElement>) {
      const opts: Select2.Options = {
        allowClear: !$select.data('disallowResultClear'),
        theme: 'endress',
        width: '100%',
        minimumResultsForSearch: $select.data('minResultsForSearch') || 6,
        closeOnSelect: false,
        placeholder: $select.data('placeholder') || 'PLACEHOLDER'
      };
      if($select.hasClass('marker-product-segment')) {
        $.extend(opts, {
          templateResult: function (result: Select2.OptionData): JQuery<Element> | string | null {
            if (!result.id) {
              return null;
            }
            const resultCount = $(result.element).data('count');
            return $(
`<div class="eh-flex-filter--item-content eh-layout--h-box eh-layout--justify-s-between eh-layout--align-s-start eh-p-v-m--large eh-p-v-m--medium eh-p-v-xs-2--small eh-p-v-xs-2--x-small">
  <div class="eh-layout--h-box eh-layout--flex-dynamic">
    <div class="eh-flex-filter--item-checkbox-wrapper">
      <div class="eh-layout--h-box eh-layout--align-s-start">
        <label class="eh-checkbox eh-checkbox--checkbox eh-p-t-s-2 is-small">
          <input type="checkbox" value="${result.id}" ${result.selected ? ' checked' : ''} ${(resultCount ? `` : `disabled="disabled"`)}>
          <div class="eh-checkbox--indicator"></div>
          ${result.text}
        </label>
      </div>
    </div>
    <div class="eh-layout--flex-dynamic eh-p-t-m-2 eh-p-h-2">
      <ul class="eh-flex-filter--flag-items eh-no-pointer">
        <li class="eh-flex-filter--flag-item"><span class="eh-flex-filter--flag${result.id == 'f'? ' active' : ''}">F</span></li>
        <li class="eh-flex-filter--flag-item"><span class="eh-flex-filter--flag${result.id == 'l'? ' active' : ''}">L</span></li>
        <li class="eh-flex-filter--flag-item"><span class="eh-flex-filter--flag${result.id == 'e'? ' active' : ''}">E</span></li>
        <li class="eh-flex-filter--flag-item"><span class="eh-flex-filter--flag${result.id == 'x'? ' active' : ''}">X</span></li>
      </ul>
    </div>
    <div class="eh-layout--flex-static">
     ${resultCount}
    </div>
  </div>
</div>`
            );
          }
        });
      } else {
        $.extend(opts, {
          templateResult: function (result: Select2.OptionData): JQuery<Element> | string | null {
            if (!result.id) {
              return null;
            }
            const resultCount = $(result.element).data('count');
            if( $select.prop('multiple') ) {
              return $(
                  `<div class="eh-product-filter--item-content eh-layout--h-box eh-layout--justify-s-between eh-layout--align-s-start eh-p-v-m--large eh-p-v-m--medium eh-p-v-xs-2--small eh-p-v-xs-2--x-small">
  <label class="eh-layout--h-box eh-layout--align-s-start">
    <span class="eh-product-filter--filter-checkbox eh-checkbox eh-checkbox--checkbox eh-p-t-s-2 is-small">
      <input type="checkbox" value="${result.id}" ${result.selected ? ' checked' : ''}/>
      <span class="eh-checkbox--indicator"></span>
      <span>${result.text}</span>
    </span>
  </label>
  ${(resultCount ? `<span class="eh-label eh-p-l">${resultCount}</span>` : '')}
</div>`
              );
            } else {
              return $(
                  `<div class="eh-product-filter--item-content eh-layout--h-box eh-layout--justify-s-between eh-layout--align-s-start eh-p-v-m--large eh-p-v-m--medium eh-p-v-xs-2--small eh-p-v-xs-2--x-small">
  <label class="eh-layout--h-box eh-layout--align-s-start">
    <span class="eh-product-filter--filter-radio eh-radio eh-radio--checkbox eh-p-t-s-2 is-small">
      <input type="radio" value="${result.id}" ${result.selected ? ' checked' : ''}/>
      <span class="eh-radio--indicator"></span>
      <span>${result.text}</span>
    </span>
  </label>
  ${(resultCount ? `<span class="eh-label eh-p-l">${resultCount}</span>` : '')}
</div>`
              );

            }
          }
        });
      }
      if ($select.prop('multiple')) {
        $.fn.select2.amd.require(
            [
              'cs.FilterMultiSelectResultDecorator',
              'cs.FilterMultiSelectHierarchicalDecorator',
              'cs.NoOpSelectionAdapter',
              'select2/utils',
              'select2/results',
              'select2/selection/eventRelay',
              'select2/dropdown',
              'select2/dropdown/attachContainer',
              'select2/dropdown/search',
              'select2/dropdown/minimumResultsForSearch'
            ],
            (
              FilterMultiSelectResultDecorator: object,
              FilterMultiSelectHierarchicalDecorator: object,
              NoOpSelectionAdapter: object,
              S2Utils: S2.Utils,
              S2Results: object,
              S2EventRelay: object,
              S2Dropdown: object,
              S2AttachContainer: object,
              S2DropdownSearch: object,
              S2MinimumResultsForSearch: object) => {
              
              const ResultAdapter = S2Utils.Decorate(S2Results, FilterMultiSelectResultDecorator);
              
              const SelectionAdapter = S2Utils.Decorate(
                NoOpSelectionAdapter,
                S2EventRelay
              );
          
              let DropdownAdapter = S2Utils.Decorate(
                S2Utils.Decorate(
                  S2Utils.Decorate(
                    S2Dropdown,
                    S2DropdownSearch
                  ),
                    S2MinimumResultsForSearch
                ),
                S2AttachContainer
              );

              if ($select.data('isHierarchical')) {
                opts.minimumResultsForSearch = Infinity;
                DropdownAdapter = S2Utils.Decorate(DropdownAdapter, FilterMultiSelectHierarchicalDecorator);
              }
          
              $.extend(opts, {
                closeOnSelect: false,
                selectionAdapter: SelectionAdapter,
                dropdownAdapter: DropdownAdapter,
                resultsAdapter: ResultAdapter,
                templateSelection: function (selection: { text: string } | undefined): string {
                  return selection ? selection.text : 'templateSelection(data.text)';
                }
              });
              $select.select2(opts);
          });
        }
        else {
          $.fn.select2.amd.require(
            [
              'cs.FilterMultiSelectResultDecorator',
              'cs.DropDownSearchPromptDecorator',
              'cs.NoOpSelectionAdapter',
              'select2/utils',
              'select2/results',
              'select2/selection/eventRelay',
              'select2/dropdown',
              'select2/dropdown/attachContainer',
              'select2/dropdown/search',
              'select2/dropdown/minimumResultsForSearch'
            ],
            (
              FilterMultiSelectResultDecorator: object,
              DropDownSearchPromptDecorator: object,
              NoOpSelectionAdapter: object,
              S2Utils: S2.Utils,
              S2Results: object,
              S2EventRelay: object,
              S2Dropdown: object,
              S2AttachContainer: object,
              S2DropdownSearch: object,
              S2MinimumResultsForSearch: object) => {
  
              const ResultAdapter = S2Utils.Decorate(S2Results, FilterMultiSelectResultDecorator);
              
              const SelectionAdapter = S2Utils.Decorate(
                  NoOpSelectionAdapter,
                  S2EventRelay
              );
              
              let DropdownAdapter = S2Utils.Decorate(
                S2Utils.Decorate(
                  S2Utils.Decorate(
                    S2Utils.Decorate(
                      S2Dropdown,
                      S2DropdownSearch
                    ),
                    DropDownSearchPromptDecorator
                  ),
                  S2MinimumResultsForSearch
                ),
                S2AttachContainer
              );

              $.extend(opts, {
                closeOnSelect: true,
                dropdownAdapter: DropdownAdapter,
                resultsAdapter: ResultAdapter,
                selectionAdapter: SelectionAdapter
              });
              $select.select2(opts);
            });
        }
    }
    
    
    private static makeActive($filter: JQuery<HTMLElement>) {
      if ($filter.find('.disabled').length > 0) {
        return;
      }
      $filter.toggleClass(SearchFilters.CLASS_ACTIVE, true);
      $filter.find(SearchFilters.SELECTOR__MARKER_FILTER_SELECT).select2('open');
      $filter.find(`.${eh.DatePicker.CLS_MARKER_DATEPICKER}`).show();
      $filter.find(`.${eh.DatePicker.CLS_MARKER_DATEPICKER_CONTROL}`).show();
      $filter.find('ul.eh-product-filter--items').show();
  
      $filter.siblings('.active').each((_index, el) => {
        SearchFilters.makeInActive($(el));
      });
    }
  
    
    private static makeInActive($filter: JQuery<HTMLElement>) {
      $filter.toggleClass(SearchFilters.CLASS_ACTIVE, false);
      $filter.find(SearchFilters.SELECTOR__MARKER_FILTER_SELECT).select2('close');
      $filter.find(`.${eh.DatePicker.CLS_MARKER_DATEPICKER}`).hide();
      $filter.find(`.${eh.DatePicker.CLS_MARKER_DATEPICKER_CONTROL}`).hide();
      $filter.find('ul.eh-product-filter--items').hide();
    }
    
    
    private static refreshTabLabel($base: JQuery<HTMLElement>) {
      const $tabContent = $base.selfOrClosest('.marker-tab-content');
      const containerId = $tabContent.data('csTabContainer');
      const tabContainer: eh.Tabs | undefined =
        $(`.marker-tab-container[data-cs-tab-container="${containerId}"]`).data('eh.Tabs');
      const tabId = $tabContent.attr('id');
      const resultCount: string | undefined = $(SearchFilters.SELECTOR__FILTER_RESULT, $base).data('resultCount');
      if (resultCount !== undefined && tabContainer && tabId) {
        const tab = tabContainer.getTabForId(tabId);
        if (tab) {
          [tab.tab]
            .map(el => $(el).selfOrFind('.marker-tab-label'))
            .forEach($el => SearchFilters.translateLabelWithResult($el, resultCount))
          ;
        }
      }
    }
    
  }

  export class ClippingPainterManager {

    public static scrollDispatcherDescriptor = {
      scrollPanelClassName: '.eh-produtct-filter--groups-scroll-panel',
      scrollContentClassName: '.eh-produtct-filter--groups-scroll-content',
      contextElement: null,
    };

    private static scrollDispatcher: ScrollChangeDispatcher;
    private scrollDispatcherContextElement: HTMLElement | null;

    constructor(
        public $base: JQuery<HTMLElement>,
    ) {
      this.scrollDispatcherContextElement = $base.get(0) || null;
      $(':root').on(eh.ACCORDION_EVENTS.ACCORDION_CHANGE, this.onAccordionChange);
      $(':root').on(cs.Snippet.EventIdPostReplace, this.onAfterSnippetRequest);
      $(':root').on(`${eh.TAB_EVENTS.TAB_CHANGE} ${eh.TAB_EVENTS.TAB_CONTENT_SHOWN}`, this.onTabChange);
      Breakpoints.getInstance().registerResizeListener(debounce(this.init, 200));
      this.registerScrollDispatcher(this.scrollDispatcherContextElement);
    }

    public init(): void {
      ClippingPainterManager.scrollDispatcher?.init();
    }

    private scrollDispatcherTopListener = (isAtTop: boolean): void => {
      if (isAtTop) {
        this.scrollDispatcherContextElement?.querySelector('.eh-product-filter--mobile-header')?.classList.remove('has-shadow');
      } else {
        this.scrollDispatcherContextElement?.querySelector('.eh-product-filter--mobile-header')?.classList.add('has-shadow');
      }
    };

    private scrollDispatcherBottomListener = (isAtBottom: boolean): void => {
      if (isAtBottom) {
        this.scrollDispatcherContextElement?.querySelector('.eh-product-filter--mobile-footer')?.classList.remove('has-shadow');
      } else {
        this.scrollDispatcherContextElement?.querySelector('.eh-product-filter--mobile-footer')?.classList.add('has-shadow');
      }
    };

    private onTabChange = (_e: JQuery.TriggeredEvent, t: ITab | HTMLElement | null): void => {
      if (t instanceof Tab && !t.isOwnerById('tabs-result-categories')) {
        // scoped to main tabs only
        return;
      }
      this.scrollDispatcherContextElement = t instanceof Tab ? Tabs.getTabContent(t).get(0) || null : t as HTMLElement;
      this.registerScrollDispatcher(this.scrollDispatcherContextElement);
    };

    private onAfterSnippetRequest = (_e: cs.SnippetEventPostReplace): void => {
      this.scrollDispatcherContextElement = _e.replacedTarget.get(0) || null;
      this.registerScrollDispatcher(this.scrollDispatcherContextElement);
    };

    private onAccordionChange = (_e: JQuery.TriggeredEvent, _idx: number): void => {
      this.init();
    };

    private registerScrollDispatcher = (context: HTMLElement | null): void => {
      ClippingPainterManager.scrollDispatcher?.dispose();
      ClippingPainterManager.scrollDispatcher = new ScrollChangeDispatcher({
        ...ClippingPainterManager.scrollDispatcherDescriptor,
        contextElement: context,
      });
      ClippingPainterManager.scrollDispatcher.registerTopListenerAndFire(this.scrollDispatcherTopListener);
      ClippingPainterManager.scrollDispatcher.registerBottomListenerAndFire(this.scrollDispatcherBottomListener);
    };

  }
  
  class BadgeBehaviorManager {
    
    static behavior: eh.ProductFilterBadgesBehavior | undefined;
    
    
    constructor(private $productFilter: JQuery<HTMLElement>, $filterContainer: JQuery<HTMLElement>) {
      $(':root')
        .on(cs.Snippet.EventIdPostReplace, ($event: cs.SnippetEventPostReplace): void => {
          if ($event.replacedTarget.selfOrFind('.eh-product-filter--filter-badges-content').length === 1) {
            // initialize bagde behavior only if snippet content contains badges
            this.attachContainer($event.replacedTarget.selfOrFind(SearchFilters.SELECTOR__FILTER_RESULT));
          }
        })
        .on(eh.TAB_EVENTS.TAB_CONTENT_SHOWN, ($event: JQuery.TriggeredEvent, tabContent: JQuery<HTMLElement>): void => {
          this.attachContainer($(tabContent).selfOrFind(SearchFilters.SELECTOR__FILTER_RESULT));
        })
        .on(eh.TAB_EVENTS.TAB_CHANGE, ($event: JQuery.TriggeredEvent, tab: ITab): void => {
          this.attachContainer(Tabs.getTabContent(tab));
        })
      ;
    }
    
    
    public attachContainer($filterContainer: JQuery<HTMLElement>) {
      this.deactivate();
      if ($filterContainer.length === 1) {
        this.activate($filterContainer);
      }
    }
    
    
    private activate($filterContainer: JQuery<HTMLElement>) {
      const el = $filterContainer.get(0);
      if (el) {
        BadgeBehaviorManager.behavior = new eh.ProductFilterBadgesBehavior(el);
      }
    }
    
    
    private deactivate() {
      if (!BadgeBehaviorManager.behavior) {
        return;
      }
      BadgeBehaviorManager.behavior.destroy();
      BadgeBehaviorManager.behavior = undefined;
    }
    
  }

  class SearchFilterFilterButtonBehavior {

    static BUTTON_WRAPPER_SELECTOR: string = '.eh-product-filter--filter-controls';
    static BUTTON_SELECTOR: string = '.eh-product-filter--filter-button';
    static BUTTON_SELECTOR_BUTTON: string = '.eh-button';
    static BADGES_SELECTOR: string = '.eh-product-filter--filter-badges .eh-badges .eh-badge';
    static STICKY_CLASS_NAME: string = 'is-sticky';

    static FILTER_HEADER_CLASS: string = '.eh-mobile-navigation-links';
    static FILTER_HEADER_DISPLACE_CLASS: string = eh.Header.HEADER_DISPLACE_ELEMENT_CLASS;

    private $filterButtonWrapper: JQuery<HTMLElement> | null;
    private $filterButton: JQuery<HTMLElement> | null;
    private $filterButton_Button: JQuery<HTMLElement> | null;
    private currentThreshold: number;
    private stickyGutter: number;
    private currentStickyState: boolean;
    private enabled: boolean = true;
    private currentTab: ITab;

    constructor (private _$base: JQuery<HTMLElement>) {
      $(':root').on(TAB_EVENTS.TAB_CHANGE, this.onTabChange);
      $(':root').on(TAB_EVENTS.TAB_CONTENT_SHOWN, this.onTabChange);
      Breakpoints.getInstance().registerChangeListener(this.onBreakpointChange);
    }

    private onBreakpointChange: (old: Breakpoint, current: Breakpoint) => void = (_old: Breakpoint, current: Breakpoint): void => {
      switch (true) {
        case current.id === 'small':
          this.updateThreshold();
          this.stickyGutter = 16;
          this.enabled = true;
          break;
        case current.id === 'x-small':
          this.updateThreshold();
          this.stickyGutter = 16;
          this.enabled = true;
          break;
        default: this.enabled = false;
      }
      this.invalidate();
    };

    private updateThreshold(): void {
      const headerDisplaceEl: Element | null = document.querySelector(SearchFilterFilterButtonBehavior.FILTER_HEADER_DISPLACE_CLASS);
      const headerSlotEl: Element | null = document.querySelector(SearchFilterFilterButtonBehavior.FILTER_HEADER_CLASS);
      const targetTopOffsetEl: Element | null = headerDisplaceEl || headerSlotEl;
      this.currentThreshold = !!targetTopOffsetEl ? targetTopOffsetEl.getBoundingClientRect().height : 0;
    }

    public update(): void {
      const tabContent: JQuery<HTMLElement> = Tabs.getTabContent(this.currentTab);
      this.registerContent(tabContent);
    }

    private invalidate(): void {
      this.onScrollChange();
    }

    private containsBadges($context: JQuery<HTMLElement>): boolean {
      return $(SearchFilterFilterButtonBehavior.BADGES_SELECTOR, $context).length > 0;
    }

    private onTabChange: (e: JQuery.TriggeredEvent, t: ITab) => void = (e: JQuery.TriggeredEvent, t: ITab | HTMLElement): void => {
      if (t instanceof Tab) {
        // scoped to main tabs only
        if (!t.isOwnerById('tabs-result-categories')) {
          return;
        }
      }
      if (e.type === TAB_EVENTS.TAB_CHANGE) {
        this.currentTab = t as ITab;
      }
      const isPostCommit: boolean = e.type === TAB_EVENTS.TAB_CONTENT_SHOWN;
      const tabContent: JQuery<HTMLElement> = isPostCommit ? $(t as HTMLElement): Tabs.getTabContent(t as ITab);
      this.registerContent(tabContent);
    };

    private registerContent(tabContent: JQuery<HTMLElement>): void {
      if (this.$filterButton?.length) {
        this.unregister();
      }
      if (this.containsBadges(tabContent)) {
        return;
      }
      if (tabContent.length) {
        this.$filterButtonWrapper = $(SearchFilterFilterButtonBehavior.BUTTON_WRAPPER_SELECTOR, tabContent);
        this.$filterButton = $(SearchFilterFilterButtonBehavior.BUTTON_SELECTOR, tabContent);
        this.$filterButton_Button = $(SearchFilterFilterButtonBehavior.BUTTON_SELECTOR_BUTTON, this.$filterButton);
      }
      if (this.$filterButton?.length) {
        this.register();
        this.invalidate();
      }
    }

    private unregister(): void {
      ScrollPage.unregisterChangeListener(this.onScrollChange);
      this.$filterButtonWrapper?.css({
        paddingTop: '0'
      });
      this.$filterButton?.removeClass(SearchFilterFilterButtonBehavior.STICKY_CLASS_NAME);
      this.$filterButton = null;
    }

    private register(): void {
      ScrollPage.registerChangeListener(this.onScrollChange);
    }

    private onScrollChange: () => void = (): void => {
      const btnWrapper: HTMLElement | undefined = this.$filterButtonWrapper?.get(0);
      const topOffset: number | undefined = btnWrapper?.getBoundingClientRect().top;
      let isSticky: boolean = false;
      if (topOffset !== undefined) {
        isSticky = topOffset <= this.currentThreshold + this.stickyGutter;
        const btn: HTMLElement | undefined = this.$filterButton_Button?.get(0);
        const buttonHeight: number = !!btn ? btn.getBoundingClientRect().height : 0;
        this.$filterButtonWrapper?.css({
          paddingTop: isSticky ? `${buttonHeight}px`: '0'
        });
        this.$filterButton?.toggleClass(SearchFilterFilterButtonBehavior.STICKY_CLASS_NAME, isSticky);
        this.$filterButton?.css('top', this.currentThreshold);
      }
      this.dispatchStickyState(isSticky);
    };

    private dispatchStickyState(isSticky: boolean): void {
      if (this.currentStickyState !== isSticky) {
        this.currentStickyState = isSticky;
        $(':root').trigger(eh.FILTER_BUTTON_EVENTS.STICKY_CHANGE, isSticky);
      }
    }

  }
  
}
