namespace eh {
  
  export class StoryCards {
    
    static init($base: JQuery<HTMLElement>): void {
      let expertise: JQuery<HTMLElement> = $('.eh-expertise', $base);
      $('.js-toggle-trigger-more, .js-toggle-trigger-less', expertise).each((_index, el) => {
        let bt: JQuery<HTMLElement> = $(el);
        bt.on('click', function ($event) {
          $event.preventDefault();
          const intro = bt.closest('.eh-expertise--expertise-intro');
          const currentPagePosition = eh.ScrollPage.getScrollPosition();
          const elHeight = intro.find('.js-scroll-anchor').height() || 0;
          intro.find('.js-toggle-more').toggleClass('eh--hide');
          if(bt.closest('.js-toggle-trigger-less').length !== 0) {
            eh.ScrollPage.scrollToAnimated(currentPagePosition - elHeight);
          }
        });
      });
    }
  }
}
