namespace eh {

  export class HybridDatePicker {
    
    static init($base: JQuery<HTMLElement>): void {
      HybridDatePicker.initializeDatePicker($base);
    }
    
    private static initializeDatePicker($base: JQuery<HTMLElement>): void {
      $(HybridDatePicker.DATEPICKER_SELECTOR, $base).each((_index, el) => {
        new HybridDatePicker($(el));
      });
    };
    
    public static readonly DATEPICKER_SELECTOR: string = '.js-hybrid-date-picker';
    public static readonly DATEPICKER_NATIVE_TPL: string =
        `<input class="eh-input--date-native" type="date" 
                pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}">`;

    constructor(private readonly $dp: JQuery<HTMLElement>) {

      const defaultDateFormat: string = 'yy-mm-dd';
      const opts: any = {
              dateFormat: $dp.data('date-format') || defaultDateFormat
            };

      if (!isMobileOS()) {

        // use jquery ui-datepicker on desktop
        new DatePicker($dp);

      } else {

        // prepare layout
        const originalParent: JQuery<HTMLElement> = $dp.parent();
        const elWrapper: JQuery<HTMLElement> = $('<div class="eh-layout--pos-rel eh-z-index-0"></div>')

        originalParent.append(elWrapper);

        elWrapper.append($dp);

        // disable local controls
        const calendarButtonClass: string = '.eh-input--calendar-button';
        const clearButtonClass: string = '.eh-input--clear-button';
        originalParent.find(calendarButtonClass).addClass('eh-no-pointer');
        originalParent.find(clearButtonClass).addClass('eh-no-pointer');

        const nativeEl: JQuery<HTMLElement> = $(HybridDatePicker.DATEPICKER_NATIVE_TPL),
              formatter: any = ($ as any).datepicker;

        $dp.addClass('eh-input--date-display');

        nativeEl.insertAfter($dp)
          .on('focus', (): void => {
            $dp.addClass('has-focus');
          })
          .on('blur', function() {
            $dp.removeClass('has-focus');
            const date: Date = formatter.parseDate('yy-mm-dd', nativeEl.val() as string);
            $dp.val(formatter.formatDate(opts.dateFormat, date))
                .trigger('change');
          });

      }

    }

  }

}


interface IWin extends Window {
  browserDetect(): {mobile: boolean}
}
