namespace eh {
  
  export class SliderSmallOnly {

    static init($base: JQuery<HTMLElement>): void {
      $base.find('.eh-slider-small-only').each((i, el) => {
        var slider:Slider|null = null;
        var bps = eh.Breakpoints.getInstance().registerChangeListener(function(previousBreakpoint, currentBreakpoint) {
          if (currentBreakpoint.id.indexOf('small') !== -1) {
            if (!slider) {
              slider = new eh.Slider($(el));
            }
          } else {
            if (slider) {
              slider.destroy();
              slider = null;
            }
          }
        });
      });
    }
  }
}
