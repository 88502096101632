namespace eh {
  
  export class MyDocumentsProxy {

    private static readonly API_CALL_TIMEOUT_MS = 20_000;
    private static readonly _instance: MyDocumentsProxy = new MyDocumentsProxy();
    private readonly awaitingReady: eh.DeferredPromise<any>[] = [];
    private stateReady = false;
    
    private constructor() {
    }
    
    public static getInstance(): MyDocumentsProxy {
      return MyDocumentsProxy._instance;
    }
    
    public isReady() {
      return this.stateReady;
    }
    
    public markReady() {
      this.stateReady = true;
      this.awaitingReady
          .splice(0, this.awaitingReady.length)
          .forEach(p => p.execute());
      return this;
    }
  
    public initWithElementAndConfig(baseElement: HTMLElement, config: MyDocumentsConfiguration): Promise<MyDocumentsInitialized> {
      if (!this.isReady()) {
        const p = new eh.DeferredPromise<MyDocumentsInitialized>(
            function() {
              try {
                this.resolve(window.myDocumentsApi.initWithElementAndConfig(baseElement, config));
              }
              catch (e) {
                this.reject(e);
              }
            }
        );
        this.awaitingReady.push(p);
        return Promise.race<boolean>([
          p,
          new Promise<boolean>((resolve, reject) => {
            setTimeout(reject, MyDocumentsProxy.API_CALL_TIMEOUT_MS, 'MyDocumentsApi timed out');
          })
        ]);
      }
      try {
        return window.myDocumentsApi.initWithElementAndConfig(baseElement, config);
      }
      catch (e) {
        return Promise.reject(e);
      }
    }
    
  }
  
  export type MyDocumentsInitialized = any;
  
  export type MyDocumentsConfiguration = {
    'basePath': string,
    'environment': string,
    'language': string,
    'maintenanceAnnouncement': boolean,
    'salesOrganization': string
  };
}

interface Window {
  myDocumentsApi: {
    initWithElementAndConfig: (baseElement: HTMLElement, config: eh.MyDocumentsConfiguration) => Promise<eh.MyDocumentsInitialized>;
  }
}