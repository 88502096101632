namespace eh {

    export class ProductGuideIframe {

        static init($base: JQuery<HTMLElement>): void {
            $('.marker-product-guide-iframe', $base)
                .each((_index, element) => {
                const iframe = $(element).selfOrFind('iframe');
                const msg = $(element).selfOrFind('.marker-message-display');

                const onBreakpointChange = (old: Breakpoint, current: Breakpoint) => {
                    if (current) {
                        const hide = current.id == 'small' || current.id == 'x-small';
                        iframe.toggleClass('eh--hide', hide);
                        msg.toggleClass('eh--hide', !hide);
                    }
                };
                eh.Breakpoints.getInstance().registerChangeListener(onBreakpointChange);
            });
        }
    }
}
