namespace eh {
  
  export class CountryOverview {
    private static readonly containerClass = 'eh-country-overview';
    items = <(HasVisibility & HasLabel)[]>[];
    
    static init($base: JQuery<HTMLElement>): void {
      let cc = new CountryOverview();
      if ($base.find(`.${CountryOverview.containerClass}`).length === 0) {
        return;
      }
      cc.buildIndex($base);
      cc.bindSearchField($base);
      $base.find('.ehel-country-overview--back-button').on('click', () => {
        window.history.back();
      });
      // console.log("items:" , cc.items);
      // eh.CountryOverview.initStickyList($base);
    }
    
    private buildIndex($base: JQuery<HTMLElement>) {
      $('.eh-country-chooser--category', $base).each((_index, el)  => {
        let $region = $(el), region = new Region($region);
        console.log(`Region ${region.label}`, region);
        $region.siblings(`section`).each((e, elem) => {
              if (elem.dataset.region === el.dataset.region) {
                $(elem).find('.eh-country-chooser--language-block').each((index, el) => {
                  let $country = $(el),
                      country = new Country($('.js-label', $country).text(), $('.js-label', $country).data().searchNames, region, $country);
                  this.items.push(country);
                });
              }
            }
        );
      });
    }
  
    private bindSearchField($base: JQuery<HTMLElement>) {
      let $input = $('.eh-search-bar--filter-input', $base).on('keyup', () => {
        let value = $input.val(), matchingItems = findMatching(this.items, (typeof value === 'string') ? value : '');
        console.log(value);
        this.items.forEach((item) => {
          if (matchingItems.indexOf(item) === -1) {
            item.hide();
          }
          else {
            item.show();
          }
        });
      });
      $input.focus();
    }
    
    private static initStickyList($base: JQuery<HTMLElement>) {
      new StickyLabelList($base);
    }

  }


  function findMatching(list: HasLabel[], term: string): HasLabel[] {
    if (term) {
      term = term.toLowerCase();
      return list.filter((value) => value.search.find(e => e.indexOf(term) != -1));
    }
    return list;
  }
  
  function filterItems(list: HasVisibility[], visible: boolean): HasVisibility[] {
    return list.filter((value) => value.visible === visible);
  }

  interface HasLabel {
    
    label: string;
    search: string[];
    
  }
  
  interface HasVisibility {
    
    visible: boolean;
    
    show(): void;
    hide(): void;
    
  }
  
  
  class Country implements HasVisibility, HasLabel {
  
    visible: boolean = true;
  
    constructor(public label: string, public search: string[], private region: Region, private $el: JQuery<HTMLElement>) {
      this.label = label.toLowerCase();
      region.addCountry(this);
      $el.find('.js-languages').each((e, el) => {
        if (el.dataset.searchNames) {
          search.push(el.dataset.searchNames);
        }
      });

      $el.find('[hreflang]').on('click', (ev: JQuery.TriggeredEvent) => {
        if(!LinkTarget.isExternalAnchor(ev.target)) {
          const lang = $(ev.target).attr('hreflang');
          if (lang) {
            setCookie('locale', lang, 365, false, 'Lax');
          }
        }
      });
    }
    
    show() {
      if (this.visible) {
        return;
      }
      this.visible = true;
      this.$el.show();
      this.region.show();
    }
    
    hide() {
      if (!this.visible) {
        return;
      }
      this.visible = false;
      this.$el.hide();
      this.region.hide();
    }
    
  }
  
  
  class Region implements HasVisibility {
  
    visible: boolean = true;
    countries: Country[] = [];
    section: JQuery<HTMLElement>;
    label: string;

    constructor(private $el: JQuery<HTMLElement>){
      this.label = $el.find(".js-label").text();
      this.section = $el.siblings("section").filter((e, s) =>s.dataset.region === this.label ).first();
    }
    
    addCountry(country: Country) {
      this.countries.push(country);
    }
  
    show() {
      if (this.visible) {
        return;
      }
      this.visible = true;
      this.$el.show();
      this.section.show();
    }
  
    hide() {
      console.log(`requested hide of ${this.label} ${this.visible} ${filterItems(this.countries, true).length }`);
      if (this.visible && filterItems(this.countries, true).length === 0) {
        console.log(`hiding of ${this.label}`);
        this.visible = false;
        this.$el.hide();
        this.section.hide();
      }
    }
    
  }

  class StickyLabelList {

    private readonly _component: JQuery<HTMLElement>;
    private readonly _groups: JQuery<HTMLElement>;
    private readonly _sticky: JQuery<HTMLElement>;
    private _scroller: JQuery<HTMLElement>;
    private _sections: JQuery<HTMLElement>;
    private _stickyLabel: JQuery<HTMLElement>;
    private _heightmap: any = null;
    private _lastLabelOffset: number = 0;
    private _lastLabelText: string = '';
    private _ticking: boolean;

    constructor(scope: JQuery<HTMLElement>) {
      this._component = $('.eh-country-chooser', scope);
      this._scroller = $('.eh-country-chooser--content-body', this._component);
      this._groups = $('.eh-country-chooser--categories', this._component);
      this._sections = $('.eh-country-chooser--category', this._groups);
      this._sticky = $('.eh-country-chooser--category-heading.sticky', this._component);
      this._stickyLabel = $('.eh-country-chooser--category-headline', this._sticky);

      this.init();
    }

    private init(): void {
      const eventParams: any = !!eh.Modernizr?.passiveeventlisteners ? { passive: true } : {};
      const scrollerEl: HTMLElement | undefined = this._scroller.get(0);
      if (scrollerEl) {
        scrollerEl.addEventListener('scroll', () => {
            if (!this._ticking) {
                this._ticking = true;
                window.requestAnimationFrame(() => {
                    this.stickyLabelHandler();
                    this._ticking = false;
                });
            }
        }, eventParams);
      }
      this.stickyLabelHandler();
      window.addEventListener('resize', (): void => {
          this._heightmap = null;
      });
    }

    private stickyLabelHandler = (): void => {
      if (!this._heightmap) {
        this._heightmap = [];
        let nextOffset = 0;
        this._sections.each((idx: number, s: HTMLElement): void => {
          let $s: any = $(s),
            label: any = $('.eh-country-chooser--category-headline', $s),
            labelHeight = label.get(0).getBoundingClientRect().height,
            curOffset = $s.get(0).getBoundingClientRect().height + nextOffset;
          this._heightmap.push(
            {
              min: curOffset - labelHeight,
              max: curOffset,
              label: label.get(0).innerText
            });
          nextOffset = curOffset;
        });
      }
      let offset = this._scroller.scrollTop() ?? 0,
        newIndex,
        i = 0;
      while(this._heightmap[i].min < offset) {
        i++;
      }
      newIndex = (this._heightmap.length - 1 > i) ? i: this._heightmap.length - 1;

      let activeIdx = (newIndex - 1 < 0) ? 0: newIndex - 1,
        currentLabel = this._heightmap[newIndex],
        currentPlacement = this._heightmap[activeIdx];

      if (offset <= currentPlacement.max && offset >= currentPlacement.min) {
        this.invalidateStickyLabel(currentPlacement.min - offset, currentPlacement.label);
      } else {
        this.invalidateStickyLabel(0, currentLabel.label);
      }

    };

    private invalidateStickyLabel = (offset: number, label: string): void => {
      if (this._lastLabelOffset !== offset) {
        this._sticky.css({'transform': 'translate3d(0, ' + offset + 'px, 0)'});
      }
      if (this._lastLabelText !== label) {
        this._stickyLabel.text(label);
      }
      this._lastLabelOffset = offset;
      this._lastLabelText = label;
    };

  }
  
}
