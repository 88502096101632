namespace eh {
  
  // restore list scroll position
  // marker-restscrollpos-list
  // marker-restscrollpos-item
  
  export class RestoreListScrollPosition {
    
    private static readonly HISTORY_STATE__SCROLL_TOP = 'eh.RestoreListScrollPosition/scrollTop';
    
    public static init($base: JQuery<HTMLElement>): void {
      $('.marker-restscrollpos-item', $base)
        .on('click', $event => {
  
          let seen = false;
          const
            $list = $($event.currentTarget).closest('.marker-restscrollpos-list'),
            $item = RestoreListScrollPosition.selfOrClosest($($event.currentTarget), '.marker-restscrollpos-item'),
            paramPage = $list.data('paramPage'),
            paramFromBeginning = $list.data('paramFromBeginning'),
            pageSize = $list.data('listPageSize'),
            numItems = $('.marker-restscrollpos-item', $list).filter((idx, el) => {
              if (seen) {
                return false
              }
              if ($item.is(el)) {
                seen = true;
              }
              return true;
            }).length
          ;
          const
            qs: string[] = [],
            qpm = RestoreListScrollPosition.buildQueryParamMap(location.search),
            o:any = {}
          ;
          o[paramPage] = Math.ceil(numItems / pageSize);
          o[paramFromBeginning] = 'true';
          
          $.each($.extend({}, qpm, o), function (k, v) {
            if (v instanceof Array) {
              $.each(v, function (i, v) {
                qs.push([k, v].join('='));
              });
            }
            else {
              qs.push([k, v].join('='));
            }
          });
          
          const url = RestoreListScrollPosition.addQueryParams(location.href, qs);
          
          const pageScrollTop = ScrollPage.getScrollPosition();
          const oo:any = {};
          oo[RestoreListScrollPosition.HISTORY_STATE__SCROLL_TOP] = pageScrollTop;
          const historyState = $.extend({}, history.state, oo);
          
          history.replaceState(historyState, '', url);
      });
      const currentState = history.state;
      if (currentState && currentState[RestoreListScrollPosition.HISTORY_STATE__SCROLL_TOP]) {
        setTimeout(() => {
          ScrollPage.scrollTo(currentState[RestoreListScrollPosition.HISTORY_STATE__SCROLL_TOP]);
        }, 0);
  
        const
            qs: string[] = [],
            qpm = RestoreListScrollPosition.buildQueryParamMap(location.search),
            $list = $('.marker-restscrollpos-item', $base).closest('.marker-restscrollpos-list'),
            paramPage = $list.data('paramPage'),
            paramFromBeginning = $list.data('paramFromBeginning')
        ;
        
        delete qpm[paramPage];
        delete qpm[paramFromBeginning];
        $.each(qpm, function (k, v) {
          if (v instanceof Array) {
            $.each(v, function (i, v) {
              qs.push([k, v].join('='));
            });
          }
          else {
            qs.push([k, v].join('='));
          }
        });
        
        const url = RestoreListScrollPosition.addQueryParams(location.href, qs);
        
        history.replaceState(null, '', url);
      }
    }
    
    
    private static buildQueryParamMap(queryString: string): {[key: string]: string | string[]} {
      const params: {[key: string]: string | string[]} = {};
      if (queryString.charAt(0) === '?') {
        queryString = queryString.substring(1);
      }
      if (queryString.length === 0) {
        return params;
      }
      $.each(queryString.split('&'), function (idx, kv) {
        const p = kv.split('='), k = p[0];
        let v: string | string[] = p[1] || '';
        if (k in params) {
          const x = params[k];
          if (x instanceof Array) {
            x.push(v);
            v = x;
          }
          else {
            v = [x, v];
          }
        }
        params[k] = v;
      });
      return params;
    }
    
    
    private static selfOrClosest($elem: JQuery<HTMLElement>, selector: string): JQuery<HTMLElement> {
      const $result = $elem.filter(selector);
      return $result.length === 0 ? $elem.closest(selector) : $result;
    }
    
    
    private static addQueryParams(url: string, params: string[]) {
      const qi = url.indexOf('?');
      const hi = url.indexOf('#');
      
      const qs = params.join('&');
      const h = (hi === -1) ? '' : url.substring(hi + 1, url.length);
      
      return url.substring(0, (qi === -1) ? ((hi === -1) ? url.length : hi) : qi) + (qs ? '?' + qs : '') + (h ? '#' + h : '');
    }
    
  }
  
}