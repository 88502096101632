namespace eh {

  export class ACCORDION_EVENTS {
    static ACCORDION_CHANGE: string = 'eh-accordion-on-change';
  }

  export class AccordionCtrl {

    static ACCORDION_SELECTOR: string = '.eh-accordion-ctrl';
    static ACCORDION_CATEGORY_SELECTOR: string = '.eh-accordion-ctrl--category-item';
    static ACCORDION_CURRENT_CONTENT_SELECTOR: string = '.eh-accordion-ctrl--current-content';
    static ACCORDION_CURRENT_CONTENT_HEADING_SELECTOR: string = '.eh-accordion-ctrl--category-heading';
    public static RELOAD_EVENT: string = "EhAccordionController.refresh"

    static init($base: JQuery<HTMLElement>): void {
      $(AccordionCtrl.ACCORDION_SELECTOR, $base).each((idx: number, accordionElement: HTMLElement): void => {
        new AccordionController(accordionElement);
      });
    }
  }

  class AccordionController {




    static CATEGORY_OPEN_CLASSNAME: string = 'is-open';
    static CATEGORY_DISABLED_CLASSNAME: string = 'disabled';
    static CATEGORY_ARIA_DISABLED_ATTR: string = 'aria-disabled';
    static CATEGORY_ARIA_EXPANDED_ATTR: string = 'aria-expanded';
    static CATEGORY_CONTENT_CLASS: string = '.eh-accordion-ctrl--category-body';

    static CATEGORY_HEADER_LABEL_SELECTOR: string = '.eh-accordion-ctrl--content-heading';
    static CATEGORY_CONTENT_HEADER_LABEL_SELECTOR: string = '.eh-accordion-ctrl--category-heading-label';

    private  _categories: HTMLElement[] = [];
    private _currentCategory: HTMLElement | null;
    private _verticalView: HTMLElement | null;
    private _isVertical: boolean = false;

    private _multiSelect: boolean = false;

    constructor(private accordion: HTMLElement) {
      this._isVertical = this.accordion.classList.contains('vertical');
      this._multiSelect = this.accordion.classList.contains('multiSelect');

      this._categories = nodelistToArray(this.accordion.querySelectorAll(AccordionCtrl.ACCORDION_CATEGORY_SELECTOR));
      this.init();
      let activeIndex: string | null = this.accordion.getAttribute('data-selected-index');

      // automatic open first group
      if (!this._isVertical) {
        activeIndex = activeIndex || '0';
      }

      if (!!activeIndex) {
        if(parseInt(activeIndex, 10) > -1) {
          const categoryToSelect: HTMLElement = this._categories[parseInt(activeIndex, 10)];
          if (categoryToSelect) {
            this.selectCategory(categoryToSelect);
          } else {
            throw new Error(`Accordion category index is out of bounds ${activeIndex} ${this._categories}`);
          }
        }
      }
      const a = this;
      accordion.addEventListener(AccordionCtrl.RELOAD_EVENT, (e) => {
        a.refresh(e);
      });
    }

    private  refresh(e: Event){
      if (this.accordion) {
        this._categories = nodelistToArray(this.accordion.querySelectorAll(AccordionCtrl.ACCORDION_CATEGORY_SELECTOR));
      }
      this.init();
    }

    public disableCategory(category: HTMLElement): void {
      category.classList.add(AccordionController.CATEGORY_DISABLED_CLASSNAME);
      this.setAriaDisabledFlag(category, true);
    }

    public enableCategory(category: HTMLElement): void {
      category.classList.remove(AccordionController.CATEGORY_DISABLED_CLASSNAME);
      this.setAriaDisabledFlag(category, false);
    }

    private init(): void {
      this._categories.forEach((categoryElement: HTMLElement): void => {
        this.registerHandler(categoryElement);
      });
      if (this._isVertical) {
        this.registerBackButton();
        requestAnimationFrame(() => {
          this.accordion.classList.add('transition-active');
        });
      }
    }

    private registerHandler(categoryElement: HTMLElement): void {
      categoryElement.addEventListener('click', this.onCategoryClicked);
    }

    private registerBackButton(): void {
      this._verticalView = this.accordion.querySelector(AccordionCtrl.ACCORDION_CURRENT_CONTENT_SELECTOR);
      if (!this._verticalView) {
        throw new Error('this._verticalView is missing in template structure - not able to attach the controller');
      }
      const backButton: HTMLElement | null = this._verticalView.querySelector(AccordionCtrl.ACCORDION_CURRENT_CONTENT_HEADING_SELECTOR);
      if (!backButton) {
        throw new Error('backButton is missing in template structure - not able to attach the controller');
      }
      backButton.addEventListener('click', () => {
        if(backButton.classList.contains(AccordionController.CATEGORY_DISABLED_CLASSNAME)) {
          return;
        }
        this.deselectCurrentCategory();
        this.dispatchChange();
      });
    }

    private isContentContext(event: Event): boolean {
      if (event.target) {
        return $(event.target).selfOrClosest(AccordionController.CATEGORY_CONTENT_CLASS).length > 0;
      }
      return true;
    }

    private deselectCurrentCategory: () => void = (): void => {
      if (this._currentCategory) {
        if (this._isVertical && this._verticalView) {
          $(this._currentCategory).append($(AccordionController.CATEGORY_CONTENT_CLASS, this._verticalView));
        }
        this.deselectCategory(this._currentCategory);
      }
    };

    private onCategoryClicked: (e: Event) => void = (e: Event): void => {
      // guard content context.
      if (this.isContentContext(e)) {
        return;
      }

      const category: HTMLElement = e.currentTarget as HTMLElement;

      if (this.isDisabled(category)) {
        return;
      }

      if (this._multiSelect) {
        if (category.classList.contains(
          AccordionController.CATEGORY_OPEN_CLASSNAME
        )) {
          this.deselectCategory(category);
        } else {
          this.selectCategory(category);
        }

      } else {
        if (category === this._currentCategory) {
          this.deselectCurrentCategory();
          this.dispatchChange();
          return;
        }

        this.deselectCurrentCategory();
        this.selectCategory(category);
      }

      this.dispatchChange();
    };

    private selectCategory(category: HTMLElement): void {
      category.classList.add(AccordionController.CATEGORY_OPEN_CLASSNAME);
      this.accordion.classList.add(AccordionController.CATEGORY_OPEN_CLASSNAME);
      this.setAriaExpandedFlag(category, true);
      this._currentCategory = category;
      if (this._isVertical && this._verticalView) {
        $(this._verticalView).append($(AccordionController.CATEGORY_CONTENT_CLASS, this._currentCategory));
        $(AccordionController.CATEGORY_HEADER_LABEL_SELECTOR, this.accordion)
            .text(
                $(AccordionController.CATEGORY_CONTENT_HEADER_LABEL_SELECTOR, category).text()
            );
      }
    }

    private deselectCategory(category: HTMLElement): void {
      category.classList.remove(AccordionController.CATEGORY_OPEN_CLASSNAME);
      this.accordion.classList.remove(AccordionController.CATEGORY_OPEN_CLASSNAME);
      this.setAriaExpandedFlag(category, false);
      this._currentCategory = null;
    }

    private setAriaDisabledFlag(category: HTMLElement, value: boolean): void {
      category.setAttribute(AccordionController.CATEGORY_ARIA_DISABLED_ATTR, value.toString());
    }

    private setAriaExpandedFlag(category: HTMLElement, value: boolean): void {
      category.setAttribute(AccordionController.CATEGORY_ARIA_EXPANDED_ATTR, value.toString());
    }

    private isDisabled(category: HTMLElement): boolean {
      return category.classList.contains(AccordionController.CATEGORY_DISABLED_CLASSNAME);
    }

    /**
     * index of current category or -1 if none
     * @private
     */
    private getCurrentCategoryIndex(): number {
      return this._categories.indexOf(this._currentCategory as HTMLElement);
    }

    private dispatchChange(): void {
      $(':root').trigger(ACCORDION_EVENTS.ACCORDION_CHANGE, this.getCurrentCategoryIndex());
    }

  }

}
