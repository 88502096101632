namespace eh {

    /**
     * Tabs interactivity controller - handles freemarker templates.
     * All markup must be received prerendered.
     * The only objective is to handle visibility of elements in the main-area
     * and the required dropdown.
     */

    type offsetModes = 'single' | 'row';

    export class Tabs {
    
        private static readonly logger = log.getLogger('eh.Tabs');
        private static TABS_SELECTOR: string = '.eh-tabs';

        public static init($base: JQuery<HTMLElement>): void {
            $(Tabs.TABS_SELECTOR, $base).each((_i, el) => {
                new Tabs(el);
            });
        }

        public static getTabContent(tab: ITab): JQuery<HTMLElement> {
            if (!tab) {
                return $();
            }
            if (tab.content) {
                return $(tab.content);
            }
            const $tab = Tabs.getTabElements(tab).first();
            const contentId = $tab.attr('aria-controls');
            if (!contentId) {
                return $();
            }
            const $tabContents = Tabs.getTabContents(tab);
            return $tabContents.filter(`#${$.escapeSelector(contentId)}`);
        }

        /**
         * Gets all tab contents of same container
         */
        public static getTabContents(tab: ITab): JQuery<HTMLElement> {
            const containerId = Tabs.getTabElements(tab).first().data('csTabContainer');
            return containerId ? $(`.marker-tab-content[data-cs-tab-container="${$.escapeSelector(containerId)}"]`) : $();
        }


        private static getTabElements(tab: ITab): JQuery<HTMLElement> {
            return $([tab.tab]).filter((_index, el) => $(el).data('csTabContainer'));
        }

        private static CONTAINER_SELECTOR: string = '.eh-tabs--tab-items';
        private static CONTAINER_TABS_PANEL_SELECTOR: string = '.eh-tabs--items-panel';
        private static TAB_SELECTOR: string = '.eh-tabs--tab-item';
        private static TABS_BUTTON_PREVIOUS_SELECTOR: string = '.eh-tabs--button-previous';
        private static TABS_BUTTON_NEXT_SELECTOR: string = '.eh-tabs--button-next';

        private tabScroller: TabBarScroller;

        constructor (private readonly el: HTMLElement) {
            const container: HTMLElement | null = el.querySelector(Tabs.CONTAINER_SELECTOR);
            if (container) {
                $(container).data('eh.Tabs', this);
            }
            this.tabScroller = new TabBarScroller(
                this,
                el,
                container as HTMLElement,
                el.querySelectorAll(Tabs.TAB_SELECTOR),
                el.querySelector(Tabs.CONTAINER_TABS_PANEL_SELECTOR) as HTMLElement,
                el.querySelector(Tabs.TABS_BUTTON_PREVIOUS_SELECTOR) as HTMLElement,
                el.querySelector(Tabs.TABS_BUTTON_NEXT_SELECTOR) as HTMLElement,
                el.dataset.selectedId || '0',
                'row'
            );
            this.init();
        }

        public isOwnerById: (id: string) => boolean = (id: string): boolean => {
            return this.el.getAttribute('id') === id;
        };

        public getTabForId(idString: string): ITab | undefined {
            return this.tabScroller.getTabById(this.tabScroller.parseTabId(idString));
        }

        private init (): void {
            this.tabScroller.registerChangeListenerAndFire(this.onTabSelectionChange);
            this.registerRequestEvent();
        }

        private registerRequestEvent(): void {
            $(':root').on(TAB_EVENTS.TAB_ACTIVATE_REQUEST, ($event: JQuery.TriggeredEvent, tab: HTMLElement | string): void => {
                const reqId = this.tabScroller.parseTabId(tab);
                const reqTab: ITab | undefined = this.tabScroller.getTabById(reqId);
                if (reqTab) {
                    this.tabScroller.setSelectionById(reqId);
                    eh.ScrollPage.scrollTo(0);
                }
            });
        }

        private onTabSelectionChange: (tab: ITab) => void = (tab: ITab): void => {
            $(':root').trigger(TAB_EVENTS.TAB_CHANGE, tab);
            const tabId = this.tabScroller.parseTabId(tab.id);
            
            Tabs.logger.debug('onTabSelectionChange', tab.id, tabId);
            const historyParamName = $(this.el).data('csTabParam');
            if (historyParamName) {
                const url = window.location.href;
                const qpm = URLHelper.buildQueryParamMap(URLHelper.parse(url).search);
                if (!(historyParamName in qpm) || qpm[historyParamName].indexOf(tabId) !== 0) { // history param does not start with tab id
                    qpm[historyParamName] = tabId;
                    window.history.replaceState(null, document.title, URLHelper.buildUrl(window.location.href, URLHelper.buildQueryString(qpm)));
                }
            }

            if ($(this.el).hasClass('marker-cs-tracking-onsite-navigation')) {
                const newHeading: string|undefined = tabId;
                if (newHeading) {
                    const siblingHeadings = this.tabScroller.getTabIds();
                    eh.Tracking.handleOnsiteNavigation(newHeading, siblingHeadings);
                }

                const tab = this.getTabForId(tabId)
                if (tab) {
                    const $subTabs = $('.marker-cs-tracking-onsite-navigation', Tabs.getTabContent(tab));
                    // TODO: notify active subtab
                }
            }
        };

    }

    class TabBarScroller {

        private static HAS_CLIPPING_LTR_CLASS: string = 'has-clipping-ltr';
        private static HAS_CLIPPING_RTL_CLASS: string = 'has-clipping-rtl';
        private static USE_TRANSITION_CLASS: string = 'use-transition';
        private static HIDE_COMPONENT_CLASS: string = 'eh-opacity-0';

        private readonly containerId: string | undefined;
        private tabs: ITab[];
        private scrollerWidth: number = 0;
        private itemsTotalWidth: number = 0;
        private currentTab: ITab | undefined;
        private _useTransition: boolean;
        private _listeners: {(tab: ITab): void;}[] = [];
        private _pendingTransition: JQuery<HTMLElement> | null = null;

        constructor(
            private readonly owner: Tabs,
            private readonly container: HTMLElement,
            private readonly scroller: HTMLElement,
            private readonly tabElements: NodeListOf<Element>,
            private readonly tabItemsPanel: HTMLElement,
            private readonly btnPrevious: HTMLElement,
            private readonly btnNext: HTMLElement,
            private readonly initialSelectedId: string,
            private readonly offsetMode: offsetModes = 'single'
        ) {
            if (!this.container || !this.scroller || !this.btnPrevious || !this.btnNext) {
                throw new Error('Tabcontrol is missing required elements');
            }
            Breakpoints.getInstance().registerResizeListener(debounce(this.update, 50));
            this.containerId = this.scroller.dataset.csTabContainer;
            this.init();
            this.registerControls();
            this.invalidateControls(this.scroller.scrollLeft);
            this.initTabById(this.initialSelectedId);
            this.useTransition(true);
            this.onPostInit();
            // ios safari takes some time to load/source the webfont
            setTimeout(() => this.update(true), 200);
        }

        public containsId(id: string): boolean {
            return !!this.getTabById(id);
        }

        public getTabIds(): string[] {
            return this.tabs.map((t: ITab): string => t.id);
        }

        public update: (force?: boolean) => void = (force?: boolean): void => {
            if (!force && this.scroller.getBoundingClientRect().width === this.scrollerWidth) {
                // prevent resize events triggered by native expand/collapse mobile browser address-bar´s
                return;
            }
            this.init();
            this.invalidateControls(this.scroller.scrollLeft);
            if (!force) {
                this.alignTabToViewport(this.currentTab);
            }
        };

        public parseTabId(tabOrId: HTMLElement | string): string {
            const tabAttrId: string | null = tabOrId instanceof HTMLElement ? tabOrId.getAttribute('id') : tabOrId;
            if (tabAttrId === null) {
                return '';
            }
            if (tabAttrId.indexOf('tab-') === 0) {
                return tabAttrId.replace(/^tab-/, '');
            }
            return tabAttrId
                .replace(/^t[rbt]-/, '')
                .replace(RegExp(`^${this.containerId}-`), '')
                .replace(RegExp( `\\..*$`),'')
            ;
        }

        public getTabByTabElement(tab: HTMLElement): ITab | undefined {
            return this.tabs.filter((_tab: ITab): boolean => _tab.tab === tab).pop();
        }

        public getTabById(id: string): ITab | undefined {
            return this.tabs.filter((_tab: ITab): boolean => _tab.id === id).pop();
        }

        public registerChangeListenerAndFire(listener: (t: ITab) => void): void {
            this.unregisterChangeListener(listener);
            this._listeners.push(listener);
            this.dispatchChange();
        }

        public unregisterChangeListener(listener: (t: ITab) => void): void {
            const idx: number = this._listeners.indexOf(listener);
            if (idx > -1) {
                this._listeners.splice(idx, 1);
            }
        }

        private onPostInit(): void {
            this.container.classList.remove(TabBarScroller.HIDE_COMPONENT_CLASS);
        }

        public setSelectionById(id: string): void {
            const tab: ITab | undefined = this.tabs
                .filter((t: ITab): boolean => t.id === this.parseTabId(id))
                .pop();
            this.selectTab(tab);
        }

        private useTransition(value: boolean): void {
            this._useTransition = value;
            if (value) {
                this.scroller.classList.add(TabBarScroller.USE_TRANSITION_CLASS);
            } else{
                this.scroller.classList.remove(TabBarScroller.USE_TRANSITION_CLASS);
            }
        }

        private init: () => void = (): void => {
            this.tabs = [];
            this.scrollerWidth = this.scroller.getBoundingClientRect().width;
            let offsetTotal: number = 0;
            nodelistToArray(this.tabElements)
                .forEach((tabElement: HTMLElement, index: number): void => {
                const width: number = tabElement.getBoundingClientRect().width;
                // add button size as offsets
                const tabLtrOffset: number = index === 0 ? 0: 22;
                const tabRtlOffset: number = index === this.tabElements.length -1 ? 0: 22;
                const tabId: string = this.parseTabId(tabElement);
                const contentElement: HTMLElement | null = document.querySelector('#content-' + tabId);
                // add range for each item
                this.tabs.push(new Tab(
                    this.owner,
                    tabId,
                    index,
                    tabElement,
                    contentElement,
                    new Range(index, offsetTotal, offsetTotal + width),
                    tabLtrOffset,
                    tabRtlOffset
                ));
                offsetTotal += width;
            });
            this.itemsTotalWidth = offsetTotal;
            if (this.currentTab) {
                // replace active reference with newly calculated element
                this.currentTab = this.getTabById(this.currentTab.id);
            }
        };

        private registerControls(): void {
            this.btnPrevious.addEventListener('click', (e) => {
                e.preventDefault();
                let callback: (c?: IClippedTabs) => void = this.offsetMode === 'single' ? this.scrollToPreviousRangeSingle: this.scrollToPreviousRangeRow;
                callback();
            });
            this.btnNext.addEventListener('click', (e) => {
                e.preventDefault();
                let callback: (c?: IClippedTabs) => void = this.offsetMode === 'single' ? this.scrollToNextRangeSingle: this.scrollToNextRangeRow;
                callback();
            });
            this.tabs.forEach((tab: ITab): void => {
                tab.tab.addEventListener('click', this.onItemSelect);
            });
            const eventParams: any = eh.Modernizr?.passiveeventlisteners ? { passive: true } : {};
            this.scroller.addEventListener('scroll', debounce(this.onScrollingScroller, 100), eventParams);
        }

        private onScrollingScroller: any = (_el: HTMLElement, _e: Event): any => {
            this.invalidateControls(this.scroller.scrollLeft);
        };

        private onItemSelect: (e: MouseEvent) => void = (e: MouseEvent): void => {
            e.preventDefault();
            const tab: ITab | undefined = this.getTabByTabElement(e.currentTarget as HTMLElement);
            this.selectTab(tab);
        };

        /**
         * special handler to align initial tabs to the left border of
         * the viewport
         *
         * @param id
         * @private
         */
        private initTabById(id: string): void {
            const tab: ITab | undefined = this.tabs
                .find((t: ITab): boolean => t.id === this.parseTabId(id));

            if (!tab || tab.disabled) {
                return;
            }
            if (
                tab !== this.currentTab
                && !this.isAlignedLeft(tab)
                || tab === this.currentTab
            ) {
                this.alignTabToLeftViewport(tab);
            }
            this.setCurrentTab(tab);
        }

        private selectTab(tab: ITab | undefined): void {
            if (!tab || tab.disabled) {
                return;
            }
            if (
                tab !== this.currentTab
                && !this.isAlignedLeft(tab)
                || tab === this.currentTab
            ) {
                this.alignTabToViewport(tab);
            }
            this.setCurrentTab(tab);
        }

        private setCurrentTab(tab: ITab): void {
            if (this.currentTab) {
                this.currentTab.active = false;
            }
            this.currentTab = tab;
            this.currentTab.active = true;
            this.dispatchChange();
        }

        private dispatchChange(): void {
            if (!this.currentTab) {
                return;
            }
            this._listeners.forEach((listener: (t: ITab) => void): void => listener(this.currentTab as ITab));
        }

        private doScroll(targetPos: number): void {
            if (this._pendingTransition) {
                this._pendingTransition.stop();
            }
            if (this._useTransition) {
                const duration: number = Math.max(300, Math.abs(this.scroller.scrollLeft - targetPos) * 1.5);
                this._pendingTransition = $(this.scroller).animate({
                    scrollLeft: targetPos
                }, duration, 'easeInOutSine');
            } else {
                this.scroller.scrollLeft = targetPos;
            }
        }

        private alignTabToLeftViewport(tab: ITab | undefined): void {
            if (!tab) {
                return;
            }
            let scrollLeftTarget: number = Math.max(0, tab.range.min - tab.ltrOffset);
            // align to the left
            this.doScroll(scrollLeftTarget);
            this.invalidateControls(scrollLeftTarget);
        }

        private alignTabToViewport(tab: ITab | undefined): void {
            if (!tab) {
                return;
            }
            let scrollLeftTarget: number = Math.max(0, tab.range.min - tab.ltrOffset);
            // align to the left if tab is clipped to the left
            if (
                scrollLeftTarget < this.scroller.scrollLeft
                && !this.isAlignedLeft(tab)
            ) {
                this.doScroll(scrollLeftTarget);
                this.invalidateControls(scrollLeftTarget);
                return;
            }

            const safetyRange: number = this.itemsTotalWidth - this.scrollerWidth;
            let scrollRightTarget: number = Math.min(safetyRange, tab.range.max - this.scrollerWidth + tab.ltrOffset);
            // align to the left if tab is clipped to the right the first time on selection
            if (
                scrollRightTarget > this.scroller.scrollLeft
                && !tab.active
                && tab.range.size > this.scrollerWidth - tab.offsets
            ) {
                this.doScroll(scrollLeftTarget);
                this.invalidateControls(scrollLeftTarget);
                return;
            }

            // align to the right if tab is clipped to the right
            if (scrollRightTarget > this.scroller.scrollLeft) {
                this.doScroll(scrollRightTarget);
                this.invalidateControls(scrollRightTarget);
                return;
            }
        }

        private isAlignedLeft(t: ITab): boolean {
            const offset: number = t.ltrOffset;
            const placementRatio: number = (this.scroller.scrollLeft + offset) / t.range.min;
            const epsilon: number = Number.EPSILON || Math.pow(2, -52);
            const result: number = Math.round((placementRatio + epsilon) * 100) / 100;
            return isNaN(result) || result === 1;
        }

        private static getTabOptional(r: ITab | undefined, opt: ITab): ITab {
            return r || opt;
        }

        private scrollToPreviousRangeSingle: (clippedTabs?: IClippedTabs) => void = (_clippedTabs?: IClippedTabs): void => {
            const clippedTabs: IClippedTabs = _clippedTabs || this.getClippedTabs();
            const targetTab: ITab = TabBarScroller.getTabOptional(clippedTabs.left, this.tabs[0]);
            let scrollLeftTarget: number = targetTab.range.min - targetTab.ltrOffset;
            this.doScroll(scrollLeftTarget);
            this.invalidateControls(scrollLeftTarget);
        };

        private scrollToNextRangeSingle: (clippedTabs?: IClippedTabs) => void = (_clippedTabs?: IClippedTabs): void => {
            const clippedTabs: IClippedTabs = _clippedTabs || this.getClippedTabs();
            const targetTab: ITab = TabBarScroller.getTabOptional(clippedTabs.right, this.tabs[this.tabs.length - 1]);

            // align item left first if size exceeds parent
            if (
                // is larger
                targetTab.range.size > this.scrollerWidth - 44
                // is not aligned left
                && !this.isAlignedLeft(targetTab)
            ) {
                this.scrollToPreviousRangeSingle({left: clippedTabs.right});
                return;
            }

            let scrollLeftTarget: number = targetTab.range.max - this.scrollerWidth + targetTab.rtlOffset;
            this.doScroll(scrollLeftTarget);
            this.invalidateControls(scrollLeftTarget);
        };

        private scrollToPreviousRangeRow: (clippedTabs?: IClippedTabs) => void = (_clippedTabs?: IClippedTabs): void => {
            const clippedTabs: IClippedTabs = _clippedTabs || this.getClippedTabs();
            let targetTab: ITab = TabBarScroller.getTabOptional(clippedTabs.left, this.tabs[0]);
            const scrollerWidth: number = this.scrollerWidth;
            const inRangeScrollerWidth: number = scrollerWidth - targetTab.range.size - 44;

            let targetTabIdx: number = targetTab.index;
            let lastAddedTabOffset: number = targetTab.ltrOffset;
            let scrollLeftTarget: number = targetTab.range.min;
            let toAddOffsetInsideRange: number = 0;
            while (targetTabIdx > 0 && toAddOffsetInsideRange < inRangeScrollerWidth) {
                --targetTabIdx;
                targetTab = this.tabs[targetTabIdx];
                let nextRange: IRange = targetTab.range;
                let tempOffset: number = toAddOffsetInsideRange + nextRange.size;
                if (tempOffset < inRangeScrollerWidth) {
                    lastAddedTabOffset = targetTab.ltrOffset;
                    toAddOffsetInsideRange += nextRange.size;
                } else {
                    targetTabIdx = 0;
                }
            }
            scrollLeftTarget -= toAddOffsetInsideRange + lastAddedTabOffset;
            this.doScroll(scrollLeftTarget);
            this.invalidateControls(scrollLeftTarget);
        };

        private scrollToNextRangeRow: (clippedTabs?: IClippedTabs) => void = (_clippedTabs?: IClippedTabs): void => {
            const clippedTabs: IClippedTabs = _clippedTabs || this.getClippedTabs();
            let targetTab: ITab = TabBarScroller.getTabOptional(clippedTabs.right, this.tabs[this.tabs.length - 1]);

            // align item left first if size exceeds parent
            if (
                // is larger
                targetTab.range.size > this.scrollerWidth - 44
                // is not aligned left
                && !this.isAlignedLeft(targetTab)
            ) {
                this.scrollToPreviousRangeSingle({left: clippedTabs.right});
                return;
            }

            const scrollerWidth: number = this.scrollerWidth;
            const inRangeScrollerWidth: number = scrollerWidth - targetTab.range.size - 44;

            let targetTabIdx: number = targetTab.index;
            let lastAddedTabOffset: number = targetTab.rtlOffset;
            let scrollLeftTarget: number = targetTab.range.max - this.scrollerWidth;
            let toAddOffsetInsideRange: number = 0;
            while (targetTabIdx < this.tabs.length -1 && toAddOffsetInsideRange < inRangeScrollerWidth) {
                ++targetTabIdx;
                targetTab = this.tabs[targetTabIdx];
                let nextRange: IRange = targetTab.range;
                let tempOffset: number = toAddOffsetInsideRange + nextRange.size;
                if (tempOffset < inRangeScrollerWidth) {
                    lastAddedTabOffset = targetTab.rtlOffset;
                    toAddOffsetInsideRange += nextRange.size;
                } else {
                    targetTabIdx = this.tabs.length;
                }
            }
            scrollLeftTarget += toAddOffsetInsideRange + lastAddedTabOffset;
            this.doScroll(scrollLeftTarget);
            this.invalidateControls(scrollLeftTarget);
        };

        private getClippedTabs(): IClippedTabs {
            const result: IClippedTabs = {} as IClippedTabs;
            const leftThreshold: number = this.scroller.scrollLeft;
            const rightThreshold: number = leftThreshold + this.scrollerWidth;
            this.tabs.forEach((t: ITab): void => {
                if (t.range.isInRange(leftThreshold)) {
                    result.left = t;
                }
                if (t.range.isInRange(rightThreshold)) {
                    result.right = t;
                }
            });
            return result;
        }

        private invalidateControls(scrollLeft: number): void {

            if (scrollLeft === 0) {
                this.tabItemsPanel.classList.remove(TabBarScroller.HAS_CLIPPING_LTR_CLASS);
            } else {
                this.tabItemsPanel.classList.add(TabBarScroller.HAS_CLIPPING_LTR_CLASS);
            }

            if (this.itemsTotalWidth - (scrollLeft + 1) > this.scrollerWidth) {
                this.tabItemsPanel.classList.add(TabBarScroller.HAS_CLIPPING_RTL_CLASS);
            } else {
                this.tabItemsPanel.classList.remove(TabBarScroller.HAS_CLIPPING_RTL_CLASS);
            }

        }

    }

    interface IRange {
        index: number;
        min: number;
        max: number;
        size: number;
        isInRange: (value: number) => boolean;
    }
    interface IClippedTabs {
        left?: ITab;
        right?: ITab;
    }
    export interface ITab {
        id: string;
        index: number;
        disabled: boolean;
        content: HTMLElement | null;
        tab: HTMLElement;
        range: IRange;
        active: boolean;
        ltrOffset: number;
        rtlOffset: number;
        offsets: number;
    }

    class Range implements IRange {
        private readonly _size: number = 0;
        constructor(
            public index: number,
            public min: number,
            public max: number
        ) {
            this._size = max - min;
        }
        public isInRange(value: number): boolean {
            return value > this.min && value < this.max;
        }
        public get size(): number {
            return this._size;
        }
    }

    export class Tab implements ITab {

        private static ACTIVE_CLASS: string = 'active';
        private static DISABLED_CLASS: string = 'disabled';
        private static TAB_HIDE_CLASS: string = 'eh--hide';

        private readonly _offsets: number;
        private _active: boolean = false;
        private _disabled: boolean;

        constructor(
            private readonly owner: eh.Tabs,
            public readonly id: string,
            private readonly _index: number,
            private readonly _tab: HTMLElement,
            private _content: HTMLElement | null,
            private readonly _range: IRange,
            private readonly _ltrOffset: number = 22,
            private readonly _rtlOffset: number = 22
        ) {
            this._offsets = _ltrOffset + _rtlOffset;
            this._disabled = _tab.classList.contains(Tab.DISABLED_CLASS);
        }

        public isOwnerById(id: string): boolean {
            return this.owner.isOwnerById(id);
        }

        public get content(): HTMLElement | null {
            return this._content;
        }

        public set content(value: HTMLElement | null) {
            this._content = value;
        }

        public get offsets(): number {
            return this._offsets;
        }

        public get ltrOffset(): number {
            return this._ltrOffset;
        }

        public get rtlOffset(): number {
            return this._rtlOffset;
        }

        public get index(): number {
            return this._index;
        }

        public get tab(): HTMLElement {
            return this._tab;
        }

        public get range(): IRange {
            return this._range;
        }

        public get active(): boolean {
            return this._active;
        }

        public set active(value: boolean) {
            this._active = value;
            if (this._active) {
                this.tab.classList.add(Tab.ACTIVE_CLASS);
                this.content?.classList.remove(Tab.TAB_HIDE_CLASS);
            } else {
                this.tab.classList.remove(Tab.ACTIVE_CLASS);
                this.content?.classList.add(Tab.TAB_HIDE_CLASS);
            }
        }

        public get disabled(): boolean {
            return this._disabled;
        }

        public set disabled(value: boolean) {
            this._disabled = value;
            if (this._disabled) {
                this.tab.classList.add(Tab.DISABLED_CLASS);
            } else {
                this.tab.classList.remove(Tab.DISABLED_CLASS);
            }
        }

    }

}
