namespace eh {
  
  export class MyDocumentsLoader {

    public static readonly SELECTOR = '.eh-my-documents-container';
    public static readonly SELECTOR__PROGRESS_INDICATOR = '.marker-my-documents-progress-indicator';
    public static readonly CLASS__HIDDEN = 'eh--hide';
    
    static init($base: JQuery<HTMLElement>, isSnippetRequest = false): void {
      if (isSnippetRequest) {
        return;
      }

      const $progressIndicator = $(MyDocumentsLoader.SELECTOR__PROGRESS_INDICATOR, $base);
      $(MyDocumentsLoader.SELECTOR, $base).each((_index, el) => {
        const $myDocumentsContainer = $(el);
        const config: MyDocumentsConfiguration = $myDocumentsContainer.data('myDocumentsConfig');
        if (!config || Object.keys(config).length === 0) {
          return;
        }
        $progressIndicator.toggleClass(MyDocumentsLoader.CLASS__HIDDEN, false);
        console.log(MyDocumentsLoader.CLASS__HIDDEN, $progressIndicator);
        MyDocumentsProxy.getInstance()
          .initWithElementAndConfig(el, config)
          .then(result => {
            console.log('MyDocumentsProxy.initWithElementAndConfig', result);
            $progressIndicator.toggleClass(MyDocumentsLoader.CLASS__HIDDEN, true);
            if (result) {
              $myDocumentsContainer.toggleClass(MyDocumentsLoader.CLASS__HIDDEN, false);
            }
            else {
              showError();
            }
          },
          reason => {
            $progressIndicator.toggleClass(MyDocumentsLoader.CLASS__HIDDEN, true);
            showError(reason);
          })
        ;
        
        const showError = (reason?: any) => {
          console.warn('Failed to load MyDocuments', reason);
          $myDocumentsContainer.html('Failed to load MyDocuments');
          $myDocumentsContainer.toggleClass(MyDocumentsLoader.CLASS__HIDDEN, false);
        };
      });
      
    }
    
  }
  
}