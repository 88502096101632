namespace eh {
  
    export class SearchResultsDla {
      
        private static readonly SELECTOR__CARD = ".marker-dla-card-container";
        private static readonly SELECTOR__TRACKING_INFO = ".marker-cs-tracking-info";
  
        private static readonly logger = log.getLogger('eh.SearchResultsDla');
      
        public static init($base: JQuery<HTMLElement>, _isSnippetRequest: boolean = false): void {
            $base.selfOrFind(SearchResultsDla.SELECTOR__CARD).each((i, el) => {
                new SearchResultsDla($(el));
            });
        }

        private static notifyReplaced($el: JQuery<HTMLElement>): void {
          const event = jQuery.Event(cs.Snippet.EventIdPostReplace) as cs.SnippetEventPostReplace;
          event.replacedTarget = $el;
          $(':root').trigger(event);
        }
      
        private $langSelect: JQuery<HTMLElement> = $();
        private jqXHR: JQuery.jqXHR | null;
        private activeSize:String = "";
        
        public constructor(private readonly $card: JQuery<HTMLElement>) {
            this.initDownloadCard();
            Breakpoints.getInstance().registerChangeListener(this.onBreakpointChange);
            this.initFooter();
        }
      
        private onBreakpointChange: (old: Breakpoint, current: Breakpoint) => void = (_old: Breakpoint, current: Breakpoint): void => {
          if(this.activeSize === '' || current.id !== _old.id) {
            this.activeSize = current.id;
            this.initLangLabels(current.id);
          }
        }

        private initLangLabels(size: String): void {/* switch label set if necessary, rebuild select2 **/
          this.$langSelect.each((_idx, elem) => {
            const sel = $(elem);
            let val: string = sel.data('labelsActive');
            switch (true) {
              case size === 'small':
              case size === 'x-small':
                val = 'labelSmall';
                break;
              default: 
                val = 'labelLarge';
            }
            if(val !== sel.data('labelsActive')) {
              sel.data('labelsActive', val);
              sel.data('placeholder', sel.data(val));
              $('option', sel).each((_idx, elem) => {
                  const opt = $(elem);
                  opt.text(opt.data(val));
              });

              if(sel.hasClass("select2-hidden-accessible")) {
                sel.select2('destroy');
              }
            }
          });
          this.initSelect2();
        }

        private initSelect2() {/* build select2 if not initialized before **/
          this.$langSelect.each((_idx, elem) => {
            const $elem = $(elem);
            if(!$elem.hasClass('select2-hidden-accessible')) {
              $elem.select2({
                allowClear: !this.$langSelect.data('disallowResultClear'),
                theme: 'endress',
                width: '100%',
                minimumResultsForSearch: this.$langSelect.data('minResultsForSearch') || 6,
                closeOnSelect: true,
                placeholder: this.$langSelect.data('placeholder') || 'PLACEHOLDER'
              });
            }
          });
        }
      
        private initDownloadCard(): void {/* use only once on html **/
          if (this.$langSelect) {
            this.$langSelect.select2('destroy');
          }
          this.$langSelect = $('.select2-download', this.$card);
          this.initLangLabels(this.activeSize);

          this.$card.find('input[type=radio]').on('change', ($event) => {
            const radio = $($event.target);
            if (radio.prop('checked')) {
              this.activate(radio.selfOrClosest('.marker-dla-file-data'));
            }
          });

          this.$card.find('.marker-dwl-ajax-trigger').on('change', ($event) => {
            this.$card.removeData('selectedId');
            let url = $($event.target).val();
            if (url === '') {
              url = $($event.target).data('emptyValue')
            }
            this.load(''+url);
          });

          this.$card.find('.marker-dwl-ajax-url').on('click', ($event) => {
              $event.preventDefault();
            const url = $($event.target).data('url');
            this.load(''+url);
          });
          
          this.$card.find('.marker-toggle-older').on('click', ($event) => {
              $event.preventDefault();
              this.$card.find('.marker-older-row,.marker-toggle-older').toggleClass('eh--hide');
          });

          let sel = this.$card.data('selectedId');
          if (sel) {
            $(":radio[value="+sel+"]")
              .prop('checked',true)
              .trigger('change');
          }
        }

        private initFooter(): void {
          this.$card.find('.marker-copy-clipboard').on('click', ($event) => {
            $event.preventDefault();
            const dummy = document.createElement("input");
            dummy.value = '' + ($($event.currentTarget).attr('href') || '');
            document.body.appendChild(dummy);
            dummy.setAttribute("id", "dummy_id");
            dummy.select();
            document.execCommand("copy");
            document.body.removeChild(dummy);
          });
          this.$card.find('.marker-send-mail').on('click', ($event) => {
            $event.preventDefault();
            const bt = $($event.currentTarget);
            let text = (bt.data('body') || '').replace('{{url}}', ($($event.currentTarget).attr('href')?.replace(/ /g, '%20') || ''));
            text = "mailto:?subject="+encodeURIComponent(bt.data('subject') || '')+'&body='+encodeURIComponent(text);
            window.location.href = text;
          });
    }

        public activate(tagElem: JQuery<HTMLElement>): void {
          SearchResultsDla.logger.debug('activate', tagElem);
          this.$card.data('selectedId', tagElem.data('docid'));
          let dataNames = ['title', 'description', 'language', 'version', 'file-name', 'file-size', 'file-lang'];
          for(const n of dataNames) {
              let v = tagElem.data(n) || '';
              this.$card.find('.marker-dwl-'+n).text(v);
          }
          let v = tagElem.data('version-label') || 'Version';
          let s = tagElem.data('version-short') || '';
          let lave = tagElem.data('version-line') || v +' - '+s;
          this.$card.find('.marker-dwl-lang-version').text(lave);
          let p = tagElem.data('fileType') || '';
          this.$card.find('img.marker-dwl-file-type').attr('src', p);
          if(tagElem.data('en-newer')) {
              this.$card.find('.marker-dwl-en-newer').removeClass('eh--hide');
          } else {
              this.$card.find('.marker-dwl-en-newer').addClass('eh--hide');
          }
          let u = tagElem.data('file-url') || '';
          this.$card.find('.marker-dwl-file-url').attr('href', u);
          
          this.updateTrackingInfo(tagElem);
          eh.Tracking.injectTrackingEvent({}, tagElem);
        }

        private load(url:string): void {
            //console.log('doSearch', this.getTerm(), '$searchResultContainer', this.$searchResultContainer);
            this.showLoadingIndicator();
            this.jqXHR = $.ajax({
              'url': url,
              'method': 'get',
              'beforeSend': ()  => {
              }
            }).done((data) => {
              //console.log('search result');
              const resultData = $(data).find('.dwl-ajax-main');
              this.$card.find('.dwl-ajax-main').replaceWith(resultData);
              this.initDownloadCard();
              SearchResultsDla.notifyReplaced(resultData);
  
              const $file = this.$card.find('input[type=radio]:checked').selfOrClosest('.marker-dla-file-data').emptyToOptional();
              if ($file) {
                this.activate($file);
              }
            })
            .fail(function(/*jqXHR, textStatus, errorThrown*/) {
              //console.log('search failure');
            })
            .always(() => {
              this.hideLoadingIndicator();
            });
            //console.log('started jqXHR search');
          }
          
          private updateTrackingInfo($selectedElem: JQuery<HTMLElement>) {
            const $trackingInfo = this.$card.selfOrFind(SearchResultsDla.SELECTOR__TRACKING_INFO);
            ['language', 'title', 'version'].forEach(k => {
              const v = $selectedElem.data(k === 'language' ? 'lang' : k);
              const attrName = `csTrackingDownload${k.substr(0, 1).toUpperCase()}${k.substr(1)}`;
              if (v) {
                $trackingInfo.data(attrName, v);
              }
              else {
                $trackingInfo.removeData(attrName);
              }
            });
          }
          
          private showLoadingIndicator() {
            // @TODO: NYI
            // eh.LoadingIndicator.showLoading(this.$searchButton);
            //this.$progressIndicator.toggleClass(SiteSearch.CLASS__HIDDEN, false);
          }
            
          private hideLoadingIndicator() {
            // @TODO: NYI
            // eh.LoadingIndicator.hideLoading();
            //this.$progressIndicator.toggleClass(SiteSearch.CLASS__HIDDEN, true);
          }
      
    }
  
}
