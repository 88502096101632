namespace eh {

    export class FILTERED_LIST_EVENTS {
        static FILTERED_LIST_CHANGE: string = 'eh-filtered-list-on-change';
    }

    export class FilteredListCtrl {

        static FILTERED_LIST_SELECTOR: string = '.eh-filtered-list-ctrl';
        static FILTERED_LIST_ITEM_SELECTOR: string = '.eh-product-filter--item';
        static FILTERED_LIST_FILTER_INPUT_SELECTOR: string = '.eh-filtered-list-ctrl--filter-input';
        static FILTERED_LIST_FILTER_NO_RESULT_SELECTOR: string = '.eh-filtered-list-ctrl--no-result';
        static FILTERED_LIST_CLEAR_BUTTON_SELECTOR: string = '.eh-filtered-list-ctrl--clear-filter-button';
        static FILTERED_LIST_FILTER_LABEL_SELECTOR: string = '.eh-filtered-list-ctrl--list-item-label';

        static init($base: JQuery<HTMLElement>): void {
            $(FilteredListCtrl.FILTERED_LIST_SELECTOR, $base).each((idx: number, filteredListElement: HTMLElement): void => {
                const filterInput: HTMLInputElement = $(FilteredListCtrl.FILTERED_LIST_FILTER_INPUT_SELECTOR, filteredListElement).get(0) as HTMLInputElement;
                const filterClearButton: HTMLElement | null | undefined = $(FilteredListCtrl.FILTERED_LIST_CLEAR_BUTTON_SELECTOR, filteredListElement).get(0);
                const noResultLabel: HTMLElement | null | undefined = $(FilteredListCtrl.FILTERED_LIST_FILTER_NO_RESULT_SELECTOR, filteredListElement).get(0);
                const minLengthStr: string | null = filteredListElement.getAttribute('data-filter-activation-min-length');
                const minLength: number | null = !!minLengthStr ? parseInt(minLengthStr, 10) : 10;
                new FilteredListController(filteredListElement, filterClearButton, noResultLabel, filterInput, minLength);
            });
        }
    }

    export class VisibleItem {

        static ITEM_HIDE_CLASS: string = 'eh--hide';
        protected _isVisible: boolean = true;

        constructor(protected item: HTMLElement | null | undefined) {
            if (!item) {
                throw new Error('Missing required item');
            }
        }

        public get isVisible(): boolean {
            return this._isVisible;
        }

        public hide(): void {
            this._isVisible = false;
            this.item?.classList.add(VisibleItem.ITEM_HIDE_CLASS);
        }

        public show(): void {
            this._isVisible = true;
            this.item?.classList.remove(VisibleItem.ITEM_HIDE_CLASS);
        }
    }

    class FilterItem extends VisibleItem {

        static ITEM_FIRST_CLASS: string = 'is-first';
        static ITEM_LAST_CLASS: string = 'is-last';

        constructor(protected item: HTMLElement, public labelField: HTMLElement) {
            super(item);
        }

        public set isFirst(value: boolean) {
            if (value) {
                this.item.classList.add(FilterItem.ITEM_FIRST_CLASS);
            } else {
                this.item.classList.remove(FilterItem.ITEM_FIRST_CLASS);
            }
        }

        public set isLast(value: boolean) {
            if (value) {
                this.item.classList.add(FilterItem.ITEM_LAST_CLASS);
            } else {
                this.item.classList.remove(FilterItem.ITEM_LAST_CLASS);
            }
        }

    }

    class FilteredListController {

        static INPUT_COMPONENT_SELECTOR: string = '.eh-filtered-list-ctrl--filter';
        private readonly _itemsMap: FilterItem[] = [];
        private readonly _filterClear: VisibleItem | null | undefined = null;
        private readonly _noResultLabel: VisibleItem | null | undefined = null;
        private _hasFilter: boolean = false;
        private _hasNoResult: boolean = false;

        constructor(
            private filteredList: HTMLElement,
            private filterClear: HTMLElement | null | undefined,
            private noResultLabel: HTMLElement | null | undefined,
            private filterInput: HTMLInputElement,
            private minLength: number,
            ) {
            const items: HTMLElement[] = nodelistToArray(filteredList.querySelectorAll(FilteredListCtrl.FILTERED_LIST_ITEM_SELECTOR));
            this._filterClear = new VisibleItem(filterClear);
            this._noResultLabel = new VisibleItem(this.noResultLabel);
            if (items.length >= this.minLength) {
                const filterComponent: HTMLElement | null = this.filteredList.querySelector(FilteredListController.INPUT_COMPONENT_SELECTOR);
                new VisibleItem(filterComponent).show();
            }
            this._itemsMap = items.map((item: HTMLElement): FilterItem => {
                const filterLabel: HTMLElement | null = item.querySelector(FilteredListCtrl.FILTERED_LIST_FILTER_LABEL_SELECTOR);
                if (!filterLabel) {
                    throw new Error(`FilteredListController provided element is missing label (${FilteredListCtrl.FILTERED_LIST_FILTER_LABEL_SELECTOR})`);
                }
                return new FilterItem(item, filterLabel);
            });
            this.init();
        }

        private init(): void {
            this.filterInput.addEventListener('input', debounce(this.onInputChange, 100));
            if (this.filterClear) {
                this.filterClear.addEventListener('click', this.onClearButtonClick);
            }
            this.filterInput.value = this.filteredList.getAttribute('data-filter-term') ?? '';
            this.invalidate();
        }

        private invalidate(): void {
            const term: string = this.filterInput.value;
            this._hasFilter = !!term;
            this.applyFilter(term);
            this.updateControls();
        }

        private onClearButtonClick = (): void => {
            this.filterInput.value = '';
            this.invalidate();
        };

        private onInputChange = (): void => {
            this.invalidate();
        };

        private updateControls(): void {
            if (this._hasFilter) {
                this._filterClear?.show();
            } else {
                this._filterClear?.hide();
            }
            if (this._hasNoResult) {
                this._noResultLabel?.show();
            } else {
                this._noResultLabel?.hide();
            }
        }

        private applyFilter(filterTerm: string = ''): void {
            filterTerm = filterTerm.toLowerCase();
            const visibleItems: FilterItem[] = [];
            this._itemsMap.forEach((item: FilterItem): void => {
                item.isFirst = false;
                item.isLast = false;
                if (filterTerm && item.labelField.innerText.toLowerCase().indexOf(filterTerm) === -1) {
                    item.hide();
                } else {
                    item.show();
                    visibleItems.push(item);
                }
            });
            this._hasNoResult = !visibleItems.length;
            if (!this._hasNoResult) {
                visibleItems[0].isFirst = true;
                visibleItems[visibleItems.length - 1].isLast = true;
            }
            this.dispatchChange();
        };

        private dispatchChange(): void {
            $(':root').trigger(FILTERED_LIST_EVENTS.FILTERED_LIST_CHANGE);
        }

    }

}
