namespace eh {
  
  export class SearchResult {
    
    private static readonly CLASS_HIDDEN = 'eh--hide';
    private static readonly CLASS_IS_OPEN = 'is-open';
    
    static init($base: JQuery<HTMLElement>, isSnippetReq:boolean): void {
      if (!isSnippetReq && $('.marker-search-container', $base).length > 0) {
        SearchResult.focusTargetElem($base);
        $(window).on('hashchange', event => {
          SearchResult.focusTargetElem($base);
        });
      }
      SearchResult.connectDetailsToggleButton($base);
    }
  
    /**
     * Scroll result item into focus
     *
     * looks for document fragment #focus-TARGET_IDENTIFIER
     * searches target marked by class and appropriate data attribute
     */
    static focusTargetElem($base: JQuery<HTMLElement>) {
      const match = document.location.hash.match(/^#focus-(.+)$/);
      if (!match) {
        return;
      }
      const targetIdentifier = decodeURIComponent(match[1]);
      // find targetIdentifier word, delimited by spaces
      const $target = $(`.marker-focus-target[data-focus-target~="${targetIdentifier}"]`, $base);
      $target.selfOrClosest('.eh-search-result--details-content').addClass('active');
      const pageOffsetTop = $target.offset()?.top;
      if (pageOffsetTop !== undefined) {
        //TODO: sticky header's hiding some part of the scroll target. needs more universal solution
        const correction = -72;
        ScrollPage.scrollToAnimated(pageOffsetTop + correction);
      }
    }
    
    static connectDetailsToggleButton($base: JQuery<HTMLElement>) {
      $('.trigger-details-toggle', $base).on('click', ($event) => {
        $event.preventDefault();
        const $details = $($event.currentTarget)
            .selfOrClosest('.eh-toggle-button').toggleClass(SearchResult.CLASS_IS_OPEN)
            .selfOrClosest('.eh-search-result--details-content')
            .selfOrFind('.eh-product-finder--details-data');
        $details.toggleClass(SearchResult.CLASS_HIDDEN);
      });
      $('.trigger-details-open', $base).on('click', ($event) => {
        $event.preventDefault();
        const $card = $($event.currentTarget).selfOrClosest('.marker-dla-card-container');
        $card.selfOrFind('.eh-toggle-button').toggleClass(SearchResult.CLASS_IS_OPEN, true);
        $card.selfOrFind('.eh-product-finder--details-data').toggleClass(SearchResult.CLASS_HIDDEN, false);
      });
    }
    
  }
  
}