namespace eh {

    export class ProductFilterBadgesBehavior {

        static BADGES_SELECTOR_CLASS: string = '.eh-product-filter--filter-badges';
        static BADGES_CONTENT_SELECTOR_CLASS: string = '.eh-product-filter--filter-badges-content';
        static BADGES_HEADER_CLASS: string = '.eh-mobile-navigation-links';
        static BADGES_HEADER_DISPLACE_CLASS: string = eh.Header.HEADER_DISPLACE_ELEMENT_CLASS;

        static inc: number = 0;
        public id: number;

        private badges: HTMLElement | null;
        private badgesContent: HTMLElement | null;
        private destroyed: boolean;
        private scrollingElement: Document | null | undefined;
        private badgesBounds: DOMRect | undefined;
        private stickyBadgesTopGutter: number = 16;
        private badgesHeight: number;
        private badgesTop: number;
        private badgesThreshold: number;
        private positionTop: number;
        private debouncedUpdateCallback: () => void;
        private isSticky: boolean;

        constructor (private context: HTMLElement) {
            const badges: HTMLElement | null = context.querySelector(ProductFilterBadgesBehavior.BADGES_SELECTOR_CLASS);
            if (badges) {
                this.badges = badges;
                this.badgesContent = this.badges.querySelector(ProductFilterBadgesBehavior.BADGES_CONTENT_SELECTOR_CLASS);
                debounce(this.init, 0)();
                this.id = ++ProductFilterBadgesBehavior.inc;
            }
        }

        public destroy: () => void = (): void => {
            this.destroyed = true;
            this.setSticky(false);
            Breakpoints.getInstance().unregisterChangeListener(this.onBreakpointChange);
            Breakpoints.getInstance().unregisterResizeListener(this.debouncedUpdateCallback);
            this.scrollingElement?.removeEventListener('scroll', this.invalidate);
            this.scrollingElement = null;
            this.badges = null;
        };

        private onBreakpointChange: (old: Breakpoint, current: Breakpoint) => void = (old: Breakpoint, current: Breakpoint): void => {
            this.updateMetrics();
        };

        private init: () => void = (): void => {
            if (this.destroyed) {
                return;
            }
            this.debouncedUpdateCallback = debounce(this.updateMetrics);
            Breakpoints.getInstance().registerChangeListener(this.onBreakpointChange);
            Breakpoints.getInstance().registerResizeListener(this.debouncedUpdateCallback);
            this.updateMetrics();
            this.scrollingElement = this.badges?.ownerDocument;
            this.scrollingElement?.addEventListener('scroll', this.invalidate);
            this.invalidate();
        };

        private updateMetrics: () => void = (): void => {
            if (this.isSticky || !this.badges) {
                return;
            }

            const headerDisplaceEl: Element | null = document.querySelector(ProductFilterBadgesBehavior.BADGES_HEADER_DISPLACE_CLASS);
            const headerSlotEl: Element | null = document.querySelector(ProductFilterBadgesBehavior.BADGES_HEADER_CLASS);
            const targetTopOffsetEl: Element | null = headerDisplaceEl || headerSlotEl;
            this.positionTop = !!targetTopOffsetEl ? targetTopOffsetEl.getBoundingClientRect().height : 0;

            this.badgesBounds = this.badges.getBoundingClientRect();
            this.badgesHeight = this.badgesBounds.height ?? NaN;
            this.badgesTop = eh.offsetTopToBody(this.badges);
            this.badgesThreshold = this.badgesTop + this.badgesHeight;
        };

        private invalidate: () => void = (): void => {
            if (!isNaN(this.badgesThreshold) && eh.ScrollPage.getScrollPosition() > 10) {
                if (eh.ScrollPage.getScrollPosition() >= this.badgesThreshold) {
                    this.setSticky(true);
                } else {
                    this.setSticky(false);
                }
            }
        };

        private hasActiveFilter(): boolean {
            return !!this.context.querySelector('.eh-product-filter--filter-badges .eh-badges');
        }

        private setSticky(v: boolean): void {
            if (this.isSticky !== v) {
                if (v && this.hasActiveFilter()) {
                    if (this.badges) {
                        this.badges.classList.add('is-sticky');
                        if (this.badgesContent) {
                            this.badgesContent.style.top = `${this.positionTop + this.stickyBadgesTopGutter}px`;
                        }
                        this.badges.style.paddingTop = this.badgesHeight + 'px';
                    }
                } else {
                    if (this.badges) {
                        this.badges.classList.remove('is-sticky');
                        this.badges.style.paddingTop = '0';
                    }
                    this.updateMetrics();
                }
                this.isSticky = v;
            }
        }

    }

}