namespace eh {
  
  export class InfiniteScroll {

    public static init($base: JQuery<HTMLElement>, _isSnippetRequest = false): void {
      $('.increaseResult .eh-indeterminate-loader', $base).each((_index, el) => {
        const triggerDistance = 800;
        const $el = $(el);
        const scrollListener = () => {
          const pageOffsetY = eh.ScrollPage.getScrollPosition();
          const offsetTop = $el.offset()?.top;
          if (!offsetTop) {
            return;
          }
          if (offsetTop >= (pageOffsetY + window.innerHeight + triggerDistance)) {
            return;
          }
          eh.ScrollPage.unregisterChangeListener(scrollListener);
          
          const $targetDiv = $el.selfOrClosest('.result_bottom');
          const successFunction = (data: any) => {
            cs.Snippet.replaceClustered(data, $targetDiv);
          };
          cs.Snippet.preAjax($targetDiv, $targetDiv.parent().get(0));
          
          const href = $el.attr('href');
          if (href && !$el.data('noHistoryState')) {
            history.replaceState(history.state, '', href);
          }

          $.ajax($(el).data('snippetUrl'), {
            dataType: 'xml',
            success: successFunction
          });
        };
        eh.ScrollPage.registerChangeListener(scrollListener);
      });
    
    }
  }
}