namespace eh {
  
  export class SignupOneAccount {
    
    static init($base: JQuery<HTMLElement>): void {
      if($base.find('.eh-oneaccount-wrapper').length > 0) {
        new AccountForm($base.find('.eh-oneaccount-wrapper'));
      }
      if($base.find('.eh-oneaccount-success-wrapper').length > 0) {
        $base.find('.marker-send-mail').on('click', ($event) => {
          $event.preventDefault();
          const bt = $($event.currentTarget);
          let text = (bt.data('body') || ' ');
          text = bt.attr('href')+"?subject="+encodeURIComponent(bt.data('subject') || ' ')+'&body='+encodeURIComponent(text);
          window.location.href = text;
        });
      }
    }
  }
  class AccountForm {
    isCustomerElem: JQuery<HTMLElement>;

    constructor(private readonly $base: JQuery<HTMLElement>) {
      this.isCustomerElem = $base.find('.eh-one-account-registration');
      $base.find('.cs-is-customer input:radio').each((_idx, el) => {
        $(el).on('change', () => {
          this.updateFormState(''+$(el).val());
        });
        if($(el).is(':checked')) {
          this.updateFormState(''+$(el).val());
        }
      });
      $base.find('.marker-signup-countries').select2({
        theme: 'endress',
        width: '100%',
        placeholder: $(this).data('placeholder'),
        allowClear: false,
        closeOnSelect: true
      });
      $base.find('.js-trigger-hint').on('click', (event) => {
        this.showHint();
      });
    }

    private updateFormState(v:String):void {
      this.$base.find('.eh-one-account-registration--customer-number').removeClass('eh--hide');
      this.$base.find('.eh-one-account-registration--personal-data').removeClass('eh--hide');
      this.$base.find('.eh-one-account-registration--company-data').removeClass('eh--hide');
      this.$base.find('.eh-one-account-registration--captcha-data').removeClass('eh--hide');
      this.$base.find('.eh-one-account-registration--newsletter').removeClass('eh--hide');
      this.$base.find('.eh-one-account-registration--terms').removeClass('eh--hide');
      this.$base.find('.eh-one-account-registration--submit').removeClass('eh--hide');
      if (v === 'yes') {
        this.isCustomerElem.addClass('customer-no-exists');
      } else {
        this.isCustomerElem.removeClass('customer-no-exists');
      }
    } 

    private showHint(): void {
        const msg = new Message('*:open-static2-wide-id');
        msg.putParam('title', this.$base.find('.js-trigger-hint').data('heading'));
        msg.putParam('id', this.$base.find('.js-trigger-hint').data('hintId'));
        window.postMessage(msg, '*');
    }

  }
}
