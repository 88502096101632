namespace eh {
  
  export class Highlights {

    static init($base: JQuery<HTMLElement>): void {
      $('.eh-highlight .eh-button', $base).each((_index, element) => {
        $(element).on('click', (event: JQuery.TriggeredEvent) => {
            event.preventDefault();
            $(event.currentTarget).closest('.eh-highlight').toggleClass('show-all');
            $(element).hide();
          });
      });
    }
  }

}
