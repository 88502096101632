namespace eh {
  
  type CaptchaParams = {
    appKey: string,
    language: string,
    scene: string,
    scriptUrl: string,
    token: string
  };
  
  type CaptchaResponse = {
    csessionid: string,
    sig: string
  };
  
  export class AliyunCaptcha {
    
    static init($base: JQuery<HTMLElement>, isSnippetRequest = false): void {
      $('.marker-captcha', $base).each((_i, el) => {
        const $el = $(el);
        const captchaParams: CaptchaParams = $el.data('captchaParams');
        
        if (window.noCaptcha === undefined) {
          $.getScript(captchaParams.scriptUrl)
            .then((script, textStatus) => {
              new AliyunCaptcha($el, captchaParams);
            },(jqxhr, settings, exception) => {
              setTimeout(() => {
                $.getScript(captchaParams.scriptUrl)
                    .then((script, textStatus) => {
                      new AliyunCaptcha($el, captchaParams);
                    },(jqxhr, settings, exception) => {
                    })
                ;
              }, 100);
            })
          ;
        }
        else {
          new AliyunCaptcha($el, captchaParams);
        }
      });
    }
    
    constructor(private $captcha: JQuery<HTMLElement>, captchaParams: CaptchaParams) {
      const $form = $captcha.selfOrClosest('form');
      const $smsButton = $('.marker-mobile-number-verification-button', $form);
      const $errorMessage = $('.marker-mobile-number-verification-error', $form);
      const $sentMessage = $('.marker-mobile-number-verification-sent', $form);
      const $formErrMsgs = $('.marker-cn-verification-error', $form);
      const options = {
        renderTo: $captcha.prop('id'),
        appkey: captchaParams.appKey,
        language: captchaParams.language,
        scene: captchaParams.scene,
        token: captchaParams.token,
        callback: (response: CaptchaResponse) => {
          const captchaResponse = JSON.stringify({
            sessionId: response.csessionid,
            signature: response.sig,
            token: captchaParams.token
          });
          $('.marker-captcha-response', $form).val(captchaResponse);
          $smsButton
            .prop('disabled', false)
            .toggleClass('disabled', false)
            .data('title', String($smsButton.attr('title')))
            .attr('title', '');
        }
      };
      const form = $form.get(0) as HTMLFormElement;
      $smsButton
        .on('click', $event => {
          let data: {[key: string]: string} = {};
          $sentMessage.toggleClass('eh--hide', true);
          $formErrMsgs.toggleClass('eh--hide', true).toggleClass('eh--show', false);
          ($smsButton.data('mobileNumberVerificationFields') as Array<string>).forEach((fieldName) => {
            data[fieldName] = AliyunCaptcha.findFormFieldByName(form, fieldName).val() as string;
          });
          $errorMessage.toggleClass('eh--show', false);
          const $target = AliyunCaptcha.findFormFieldByName(form, $smsButton.data('mobileNumberVerificationTarget'));
          $.ajax({
            'url': $smsButton.data('mobileNumberVerificationUrl'),
            'cache': false,
            'data': data,
            'dataType': 'json',
            'method': 'post'
          })
          .done((data: MobileNumberVerificationResponse, textStatus, _response: JQuery.jqXHR) => {
            if (data.expectedValueHashed) {
              $target.val(data.expectedValueHashed);
              $sentMessage.toggleClass('eh--hide', false);
            }
            else if (data.error) {
              const messages = [];
              if (data.error.errorCaptcha) {
                messages.push(data.error.errorCaptcha);
              }
              if (data.error.errorMobileNumber) {
                messages.push(data.error.errorMobileNumber);
              }
              if (data.error.errorMobileNumberVerification) {
                messages.push(data.error.errorMobileNumberVerification);
              }
              if (data.error.errorsGlobal) {
                messages.push(...(data.error.errorsGlobal));
              }
              $errorMessage.toggleClass('eh--show', true).html(messages.join('<br/>'));
            }
          })
          .fail((_response: JQuery.jqXHR) => {
            $errorMessage.toggleClass('eh--show', true);
            $target.val('');
          })
          .always(() => {
            $smsButton
              .prop('disabled', true)
              .toggleClass('disabled', true)
              .attr('title', $smsButton.data('title'));
            nc.reset();
          });
    
      });
      const nc = new window.noCaptcha(options);
    }
    
    static findFormFieldByName(form: HTMLFormElement, fieldName: string): JQuery<HTMLElement> {
      const f = form.elements.namedItem(fieldName);
      return f instanceof HTMLElement ? $(f) : $();
    }
    
  }
  
  type MobileNumberVerificationResponse = {
    expectedValueHashed?: string,
    error?: {
      errorsGlobal?: string[],
      errorCaptcha?: string,
      errorMobileNumber?: string,
      errorMobileNumberVerification?: string
    }
  };
  
}