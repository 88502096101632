namespace eh {
  
  export class Accordion {
    
    static init($base: JQuery<HTMLElement>): void {
      $('.eh-accordion-toggle', $base).each(function (index, element) {
        let acc = $(element);
        if (acc.find('.eh-accordion-showmore').length === 0) {
          return;
        }
        let short = acc.find('.eh-accordion-content-short');
        let full = acc.find('.eh-accordion-content-full');
        acc.find('.eh-accordion-showless a').on('click', (event: JQuery.Event) => {
          event.preventDefault();
          const currentPagePosition = eh.ScrollPage.getScrollPosition();
          const shortHeight = short.height() || 0;
          short.toggle();
          full.toggle();
          const fullHeight = full.height() || 0;
          eh.ScrollPage.scrollToAnimated(currentPagePosition + (shortHeight - fullHeight) );
        });

        acc.find('.eh-accordion-showmore a').on('click', (event: JQuery.Event) => {
          event.preventDefault();
          short.toggle();
          full.toggle();
        });
      });
    }
  }
}
