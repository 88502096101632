namespace eh {
  
  export class TermHighlights {
  
    private static readonly options = { element: 'span', className: 'eh-font-sans-demi' };
    private static readonly termMinLength = 2;
    private static readonly termSeparator= ' ';
    
    static init($base: JQuery<HTMLElement>): void {
      $base.selfOrFind('.highlight_fulltext').each((_index, el) => {
        const $el = $(el);
        let term: string | number | undefined = $el.data('fulltextTerm');
        if (term) {
          term = term.toString();
          term = term.split(TermHighlights.termSeparator).filter(value => {
            return value.length >= TermHighlights.termMinLength;
          }).join(TermHighlights.termSeparator);
          if (term) {
            $el.highlight(term, TermHighlights.options);
          }
        }
      });
    }
  }

}
