namespace eh {
  
  type ExpandableListConfiguration = {
    'maxVisibleItems'?: number
  };
  
  export class ExpandableList {
    
    private static readonly SELECTOR__EXPANDABLE_LIST_BASE = '.eh-expandable-list';
    private static readonly SELECTOR__EXPANDABLE_LIST_LIST = 'ul.eh-expandable-list--list';
    private static readonly SELECTOR__EXPANDABLE_LIST_ITEMS = '> li';
    private static readonly SELECTOR__BUTTON_EXPAND = '.eh-expandable-list--show-more';
    private static readonly SELECTOR__BUTTON_COLLAPSE = '.eh-expandable-list--show-less';
    private static readonly CLASSNAME__LAST_VISIBLE = 'is-last';

    private readonly maxVisibleItems: number = 1;
  
    private readonly $list: JQuery<HTMLElement>;
    private $buttonExpand: JQuery<HTMLElement> | undefined;
    private $buttonCollapse: JQuery<HTMLElement> | undefined;
  
    static init($base: JQuery<HTMLElement>): void {
      $(ExpandableList.SELECTOR__EXPANDABLE_LIST_BASE, $base).each((_idx, el) => {
        const $expandableList = $(el);
        const config: ExpandableListConfiguration | undefined = $expandableList.data('expandableListConfig');
        const es = new ExpandableList($expandableList, config);
        $expandableList.data('eh.ExpandableList', es);
      });
    }
    
    constructor(private $elem: JQuery<HTMLElement>, config?: ExpandableListConfiguration) {
      if (config?.maxVisibleItems !== undefined) {
        this.maxVisibleItems = config.maxVisibleItems;
      }
      
      this.$list = this.$elem.selfOrFind(ExpandableList.SELECTOR__EXPANDABLE_LIST_LIST);
      this.$buttonExpand = $(ExpandableList.SELECTOR__BUTTON_EXPAND, this.$elem).emptyToOptional();
      this.$buttonCollapse = $(ExpandableList.SELECTOR__BUTTON_COLLAPSE, this.$elem).emptyToOptional();
      
      const $listItems = this.getItems();
      if ($listItems.length <= this.maxVisibleItems) {
        return;
      }
      this.toggleItems($listItems, false);
      
      this.$buttonExpand
        ?.on('click', ($event) => {
          $event.preventDefault();
          this.toggleItems(this.getItems(), true);
          this.$buttonExpand?.hide();
          this.$buttonCollapse?.show();
      })?.show();
  
      this.$buttonCollapse?.on('click', ($event) => {
        $event.preventDefault();
        this.toggleItems(this.getItems(), false);
        this.$buttonCollapse?.hide();
        this.$buttonExpand?.show();
      })?.hide();
    }
    
    private getItems(): JQuery<HTMLElement> {
      return $(ExpandableList.SELECTOR__EXPANDABLE_LIST_ITEMS, this.$list);
    }
    
    private toggleItems($listItems: JQuery<HTMLElement>, show: boolean) {
      $listItems.each((index, el) => {
        if (index >= this.maxVisibleItems) {
          $(el).toggle(show);
        }
      });
      $listItems.removeClass(ExpandableList.CLASSNAME__LAST_VISIBLE);
      $listItems.filter(':visible:last').addClass(ExpandableList.CLASSNAME__LAST_VISIBLE);
    }
    
  }
  
}
